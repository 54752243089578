import React from 'react';
import { Description } from '@globalComponents/Text/Description';
import { Input } from '@digital-retail/journey-ui-kit';
import { ErrorContainer, InputWrapper, Label } from './styles';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@app/hooks';
import {
  getCustomerEmail,
  getCustomerName,
  getStatusNotification,
  setCustomerEmail,
  setCustomerName,
} from '@redux/SendProjectEmailSlice';
import { ReactComponent as ErrorIcon } from '@icons/danger.svg';
import { useTranslation } from 'react-i18next';

const SendProjectEmail: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const customerName = useAppSelector(getCustomerName);
  const customerEmail = useAppSelector(getCustomerEmail);
  const statusNotification = useAppSelector(getStatusNotification);

  const emailInvalid = !/^\S+@\S+\.\S+$/.test(customerEmail) && customerEmail !== '';

  const onChangeCustomerName = (value: string) => {
    dispatch(setCustomerName(value));
  };
  const onChangeCustomerEmail = (value: string) => {
    dispatch(setCustomerEmail(value));
  };

  return (
    <div data-testid="send-project-email">
      <Description>{t('ENTER_NAME_AND_EMAIL')}</Description>
      <InputWrapper>
        <Label>{t('CLIENT_NAME')}</Label>
        <Input
          data-testid="customer-name-input"
          value={customerName}
          onChange={(value) => onChangeCustomerName(value)}
          placeholder={t('CLIENT_NAME_PLACEHOLDER')}
        />
      </InputWrapper>
      <InputWrapper>
        <Label>{t('CLIENT_EMAIL')}</Label>
        <Input
          data-testid="customer-email-input"
          value={customerEmail}
          onChange={(value) => onChangeCustomerEmail(value)}
          placeholder={t('CLIENT_EMAIL_PLACEHOLDER')}
          customRegex={/./}
          error={emailInvalid}
          helper={emailInvalid ? t('CLIENT_EMAIL_ERROR') : ''}
          inputMode="email"
        />
      </InputWrapper>
      {statusNotification === 'failed' && (
        <ErrorContainer>
          <ErrorIcon /> {t('ERROR_SENDING_EMAIL')}
        </ErrorContainer>
      )}
    </div>
  );
};

export default SendProjectEmail;
