import { mediaQuery } from '@globalUtils/mediaQuery';
import styled from 'styled-components';

export const SidebarWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  padding: 8px 16px 16px 16px;
  width: 100%;
  box-sizing: border-box;

  ${mediaQuery.mobileOnly(`
    flex-direction: column-reverse;
    padding: 8px 16px;
  `)}
`;

export const SubTitle = styled.h4`
  font-weight: bold;
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 8px;
  line-height: 20px;
  padding: 0 16px;
`;
