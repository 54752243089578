/* istanbul ignore file */
import { TRIMMED } from '@globalUtils/constants/trimmed';
import { dateFormat } from '@globalUtils/dateFormat';
import { IEdgeBanding, IEdgeBandings } from '@models/IEdgeBanding';
import { ICutOptimizationProps, ICuttingPieces, IWoodBoard } from '@models/IOptimization';
import { IProduct } from '@models/IProduct';
import { IWoodCut } from '@models/IWoodCut';

export const CutsRequestFormat = (
  cuts: IWoodCut[],
  product: IProduct,
  projectName: string,
  edgebandings: IEdgeBanding[]
): ICutOptimizationProps => {
  const cutList: ICuttingPieces[] = cuts.map((cut) => ({
    amount: cut.amount,
    width: cut.unitOfMeasurement === 'centimeters' ? parseInt(cut.width) * 10 : parseInt(cut.width),
    height:
      cut.unitOfMeasurement === 'centimeters' ? parseInt(cut.height) * 10 : parseInt(cut.height),
    description: cut.cutName,
    canRotate: cut.freeVein,
    edgebanding: formatEdgeBandings(cut.edgeBanding),
  }));

  const woodBoardParams: IWoodBoard = {
    code: product.id,
    description: `(${product.id}) ${product.name}`,
    height: product?.attributes.height,
    width: product.attributes.width,
  };

  return {
    clientName: 'Usuario',
    observation: '',
    fileName: projectName,
    date: dateFormat({
      format: {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      },
    }),
    hour: dateFormat({
      format: {
        hour: 'numeric',
        minute: 'numeric',
      },
    }),
    product: { ...woodBoardParams, trimmed: { ...TRIMMED } },
    edgebandings,
    cuts: cutList,
  };
};

const formatEdgeBandings = (edgeBandings: IEdgeBandings) => {
  const formattedEdgeBandings = {};
  Object.keys(edgeBandings).forEach((side) => {
    formattedEdgeBandings[side] = edgeBandings[side]?.id || null;
  });
  return formattedEdgeBandings;
};
