import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { IStatusAsyncThunk } from '@models/IStatusAsyncThunk';
import { getEdgeBandings } from '@api/lepton';
import { IEdgeBanding } from '@models/IEdgeBanding';
import { TOOLTIP_LIST } from '@globalUtils/constants/tooltipList';

type Keys = keyof typeof TOOLTIP_LIST;

export type ICuttingConfigurationReducer = {
  activeTooltip: null | typeof TOOLTIP_LIST[Keys];
  edgeBandings: IEdgeBanding[];

  status: IStatusAsyncThunk;
};

const initialState: ICuttingConfigurationReducer = {
  activeTooltip: null,
  edgeBandings: [],

  status: 'idle',
};

export const getEdgeBandingsAsync = createAsyncThunk(
  'confirmTypeOfWood/getEdgeBandingThicknessesAsync',
  async (tenant: string) => {
    const result = await getEdgeBandings(tenant);
    return result;
  }
);

export const cuttingConfigurationSlice = createSlice({
  name: 'cuttingConfiguration',
  initialState,
  reducers: {
    toggleTooltip: (
      state,
      action: PayloadAction<ICuttingConfigurationReducer['activeTooltip']>
    ) => {
      state.activeTooltip = state.activeTooltip === action.payload ? null : action.payload;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getEdgeBandingsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        getEdgeBandingsAsync.fulfilled,
        (state, action: PayloadAction<ICuttingConfigurationReducer['edgeBandings']>) => {
          state.status = 'idle';
          state.edgeBandings = action.payload;
        }
      )
      .addCase(getEdgeBandingsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

//Export actions
export const { toggleTooltip } = cuttingConfigurationSlice.actions;

//Definición de selectores
export const getActiveTooltip = (state: RootState) => state.cuttingConfiguration.activeTooltip;
export const getEdgeBandingList = (state: RootState) => state.cuttingConfiguration.edgeBandings;

export default cuttingConfigurationSlice.reducer;
