import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

type StepperState = {
  tenant: string;
  priceGroup: string;
  productId: string;
  theme?: string;
};

const initialState: StepperState = {
  tenant: '',
  priceGroup: '',
  productId: '',
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setTenant: (state, action: PayloadAction<StepperState['tenant']>) => {
      state.tenant = action.payload;
    },
    setPriceGroup: (state, action: PayloadAction<StepperState['priceGroup']>) => {
      state.priceGroup = action.payload;
    },
    setProductId: (state, action: PayloadAction<StepperState['productId']>) => {
      state.productId = action.payload;
    },
    setTheme: (state, action: PayloadAction<StepperState['theme']>) => {
      state.theme = action.payload;
    },
  },
});

//Export actions
export const { setTenant, setPriceGroup, setProductId, setTheme } = configSlice.actions;

//Definición de selectores
export const getTenant = (state: RootState) => state.config.tenant;
export const getPriceGroup = (state: RootState) => state.config.priceGroup;
export const getProductId = (state: RootState) => state.config.productId;
export const getTheme = (state: RootState) => state.config.theme;

export default configSlice.reducer;
