import styled from 'styled-components';

export const InputWrapper = styled.div`
  padding: 0 16px 16px 16px;
`;

export const Label = styled.p`
  color: #333333;
  font-weight: bold;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 2px;
`;

export const ErrorContainer = styled.div`
  background: #f7cac8;
  color: #721c24;
  padding: 10px 20px 10px 10px;
  margin: 0 16px 16px 16px;
  display: block;
  width: fit-content;
  & svg {
    vertical-align: middle;
  }
`;
