/* istanbul ignore file */

import { IEmailNotificationProps, IEmailNotificationResponse } from '@models/IEmailNotification';

const woodServiceUrl = process.env.REACT_APP_WOOD_SERVICE_URL || '';

export const emailNotification = async ({
  email,
  name,
  pdf_url,
  project_name,
  tenant,
}: IEmailNotificationProps): Promise<IEmailNotificationResponse> => {
  const response = await fetch(`${woodServiceUrl}/notifications/email`, {
    method: 'POST',
    body: JSON.stringify({
      name,
      email,
      project_name,
      pdf_url,
    }),
    headers: {
      'Content-Type': 'application/json',
      tenant,
    },
  });

  const data = response.json();

  return response.ok ? data : Promise.reject(data);
};
