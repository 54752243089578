import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Button } from '@digital-retail/journey-ui-kit';
import { SidebarWrapperStyled } from '@features/CuttingConfiguration/styles';
import { setShowCuttingActionsId } from '@redux/CuttingListSlice';
import { nextPosition, previousPosition } from '@redux/StepperSlice';
import { addCut, clearFormValues, getFormAction, getFormIsValid } from '@redux/WoodSlice';
import { useTranslation } from 'react-i18next';

const CuttingConfigurationSidebar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const formIsValid = useAppSelector(getFormIsValid);
  const formAction = useAppSelector(getFormAction);

  const handleClickBackward = () => {
    if (formAction === 'add' || formAction === 'update') {
      dispatch(clearFormValues());
      dispatch(nextPosition());
      dispatch(setShowCuttingActionsId(null));
    } else {
      dispatch(previousPosition());
    }
  };

  const handleClickForward = () => {
    dispatch(addCut());
    dispatch(nextPosition());
    dispatch(setShowCuttingActionsId(null));
  };

  return (
    <SidebarWrapperStyled>
      <Button data-testid="backward" onClick={handleClickBackward} variant="ghost">
        {formAction === 'update' ? t('CANCEL') : t('GO_BACK')}
      </Button>
      <Button data-testid="forward" disabled={!formIsValid} onClick={handleClickForward}>
        {formAction === 'update' ? t('UPDATE') : t('CONTINUE')}
      </Button>
    </SidebarWrapperStyled>
  );
};

export default CuttingConfigurationSidebar;
