type IFullDate = {
  timeStyle?: 'short' | 'long';
  dateStyle?: 'short' | 'long';
};

type dateProps = {
  format?: IFullDate | any;
  idiom?: string;
};
type dateFormatProps = dateProps;

const defaultProps = {
  format: {
    timeStyle: 'short',
  },
  idiom: 'es',
};

const dateFormat = ({ format: options, idiom: locale }: dateFormatProps) => {
  return new Intl.DateTimeFormat(locale, options)
    .format(new Date())
    .replace(/\//g, '-')
    .replace(/,/g, '');
};

dateFormat.defaultProps = defaultProps;

export { dateFormat };
