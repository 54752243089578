import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Input } from '@digital-retail/journey-ui-kit';
import { getProjectName, setProjectName } from '@redux/WoodSlice';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Description, InputWrapper, Label } from './styles';

const ProjectName: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectName = useAppSelector(getProjectName);
  const regexCondition = /^$|^(\w|\d|\s|:|-)+$/;

  const onChangeProjectName = (value: string) => {
    if (value.length <= 50) {
      dispatch(setProjectName(value));
    }
  };

  return (
    <div data-testid="project-name">
      <Description>{t('SELECT_PROJECT_NAME_DESCRIPTION')}</Description>
      <InputWrapper>
        <Label>{t('PROJECT_NAME')}</Label>
        <Input
          data-testid="project-name-input"
          customRegex={regexCondition}
          value={projectName}
          onChange={(value) => onChangeProjectName(value)}
          placeholder={t('PROJECT_NAME_PLACEHOLDER')}
        />
      </InputWrapper>
    </div>
  );
};

export default ProjectName;
