import { mediaQuery } from '@globalUtils/mediaQuery';
import styled from 'styled-components';

export const SelectedProductStyled = styled.div`
  margin: 16px 16px 16px 16px;

  display: grid;
  grid-auto-columns: max-content;
  grid-auto-rows: 1fr;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content;
  gap: 11px;
  grid-template-areas: 'productImage productInformation';
`;

export const SelectedProductImageStyled = styled.div`
  grid-area: productImage;
  width: 58px;
  height: 42px;
  position: relative;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
  }
`;

export const SelectedProductInformationStyled = styled.div`
  grid-area: productInformation;
`;

export const SelectedProductBrandStyled = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #595959;
`;

export const SelectedProductNameStyled = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #333333;
`;

export const SelectedProductPriceStyled = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;

  color: #0072ce;
`;

export const ActionsStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  padding: 0 16px 16px 16px;

  ${mediaQuery.mobileToTablet(`
    justify-content: space-around;

    & > * {
      width: 100%;
    }
  `)}
`;
