import { IUnitOfMeasurement } from '@models/IUnitOfMeasurement';

export const UNIT_OF_MEASUREMENT_KEYS = ['centimeters', 'millimeters'] as const;

export const UNITS_OF_MEASUREMENT: IUnitOfMeasurement[] = [
  {
    key: 'centimeters',
    label: 'Centímetros',
    abbreviation: 'cm',
  },
  {
    key: 'millimeters',
    label: 'Milímetros',
    abbreviation: 'mm',
  },
];
