/* istanbul ignore file */

export const TOOLTIP_LIST = {
  freeVeinHelper: 'freeVeinHelper',
  edgeBandingHeightHelper: 'edgeBandingHeightHelper',
  edgeBandingWidthHelper: 'edgeBandingWidthHelper',
  edgeBandingTop: 'edgeBandingTop',
  edgeBandingRight: 'edgeBandingRight',
  edgeBandingBottom: 'edgeBandingBottom',
  edgeBandingLeft: 'edgeBandingLeft',
} as const;

export const TOOLTIPS_SIDES_EDGEBANDING = {
  top: TOOLTIP_LIST.edgeBandingTop,
  right: TOOLTIP_LIST.edgeBandingRight,
  bottom: TOOLTIP_LIST.edgeBandingBottom,
  left: TOOLTIP_LIST.edgeBandingLeft,
};
