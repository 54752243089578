import styled from 'styled-components';

export const EdgeBandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const EdgeBandingIcon = styled.span`
  display: contents;
`;

export const EdgeBandingImage = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1.5px dashed #b2b2b2;
  font-weight: 700;
  font-size: 12px;

  &.left {
    border-left: 2px solid #ff0707;
  }

  &.right {
    border-right: 2px solid #ff0707;
  }

  &.top {
    border-top: 2px solid #ff0707;
  }

  &.bottom {
    border-bottom: 2px solid #ff0707;
  }
`;

export const ThicknessButton = styled.div`
  width: calc(100% - 12px);
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  padding: 6px;
  align-items: center;
  cursor: pointer;

  svg {
    width: 10px;
  }

  &.active svg {
    width: 17px;
    height: 17px;
  }
`;
