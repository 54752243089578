import React from 'react';
import {
  OptionDescriptionStyled,
  OptionIconStyled,
  OptionsStyled,
  OptionStyled,
  OptionTitleStyled,
} from './styles';
import { ReactComponent as SendIcon } from '@icons/send-icon.svg';
import { ReactComponent as DownloadIcon } from '@icons/download-icon.svg';
import { saveAs } from 'file-saver';
import { Description } from '@globalComponents/Text/Description';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getOptimizationResult } from '@redux/OptimizationSlice';
import { nextPosition } from '@redux/StepperSlice';
import { sendEmailVisibleVars } from '@globalUtils/constants/sendEmailVisibleVars';
import { getTenant } from '@redux/ConfigSlice';
import { useTranslation } from 'react-i18next';
import { Highlight } from '@digital-retail/journey-ui-kit';

const ProjectDownload: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentTenant = useAppSelector(getTenant);
  const sendEmailVisible = JSON.parse(
    process.env[sendEmailVisibleVars[currentTenant.toLowerCase()]] || 'false'
  );

  const optimizationResult = useAppSelector(getOptimizationResult);
  //FIXME: conectar con el store de redux
  const filePath = optimizationResult?.url_planos;
  //FIXME: aplicar regla
  const outputFileName = 'file.pdf';

  const handleClickDownload = () => {
    // eslint-disable-next-line no-console
    console.debug('Downloading');
    if (filePath) {
      saveAs(filePath, outputFileName);
    }
  };

  const handleClickSendMail = () => {
    dispatch(nextPosition());
  };

  return (
    <div data-testid="download-project">
      <Description>{t('SELECT_SAVE_OPTION')}</Description>
      <OptionsStyled>
        <OptionStyled onClick={handleClickDownload} data-testid="action-download">
          <OptionIconStyled>
            <DownloadIcon />
          </OptionIconStyled>
          <Highlight variant="notTransactional">
            <OptionTitleStyled>{t('DOWNLOAD_PDF')}</OptionTitleStyled>
          </Highlight>
          <OptionDescriptionStyled>{t('DOWNLOAD_DESCRIPTION')}</OptionDescriptionStyled>
        </OptionStyled>
        {sendEmailVisible && (
          <OptionStyled onClick={handleClickSendMail} data-testid="action-send-mail">
            <OptionIconStyled>
              <SendIcon />
            </OptionIconStyled>
            <Highlight variant="notTransactional">
              <OptionTitleStyled>{t('SEND_EMAIL')}</OptionTitleStyled>
            </Highlight>
            <OptionDescriptionStyled>{t('SEND_EMAIL_DESCRIPTION')}</OptionDescriptionStyled>
          </OptionStyled>
        )}
      </OptionsStyled>
    </div>
  );
};

export default ProjectDownload;
