import React from 'react';
import { Button } from '@digital-retail/journey-ui-kit';
import { nextPosition } from '@redux/StepperSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  ActionsStyled,
  SelectedProductStyled,
  SelectedProductBrandStyled,
  SelectedProductImageStyled,
  SelectedProductInformationStyled,
  SelectedProductNameStyled,
  SelectedProductPriceStyled,
} from './styles';
import { getSelectedProduct } from '@redux/ConfirmTypeOfWoodSlice';
import { currencyFormat } from '@globalUtils/currencyFormat';
import { closeModal } from '@globalUtils/closeModal';
import { useTranslation } from 'react-i18next';

const ConfirmTypeOfWoodSidebar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedProduct = useAppSelector(getSelectedProduct);

  const handleClickBackward = () => {
    closeModal();
  };

  const handleClickForward = () => {
    dispatch(nextPosition());
  };

  return (
    <div data-testid="confirm-type-of-wood-sidebar">
      {selectedProduct !== null && (
        <SelectedProductStyled>
          <SelectedProductImageStyled>
            <img src={selectedProduct.imageURL} />
          </SelectedProductImageStyled>
          <SelectedProductInformationStyled>
            <SelectedProductBrandStyled>{selectedProduct.brandID}</SelectedProductBrandStyled>
            <SelectedProductNameStyled>{selectedProduct.name}</SelectedProductNameStyled>
            <SelectedProductPriceStyled>
              {currencyFormat({
                number: selectedProduct.price.priceWithoutFormatting,
                currencySymbol: selectedProduct.price.currencySymbol,
                thousandSeparator: selectedProduct.price.thousandSeparator,
                decimalSeparator: selectedProduct.price.decimalSeparator,
                precision: selectedProduct.price.precision,
              })}{' '}
              {selectedProduct.price.unit}
            </SelectedProductPriceStyled>
          </SelectedProductInformationStyled>
        </SelectedProductStyled>
      )}
      <ActionsStyled>
        <Button data-testid="backward" variant="ghost" onClick={handleClickBackward}>
          {t('CANCEL')}
        </Button>
        <Button
          data-testid="forward"
          onClick={handleClickForward}
          disabled={selectedProduct === null}
        >
          {t('GO_FORWARD')}
        </Button>
      </ActionsStyled>
    </div>
  );
};

export default ConfirmTypeOfWoodSidebar;
