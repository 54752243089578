import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { UNIT_OF_MEASUREMENT_KEYS } from '@globalUtils/constants/unitsOfMeasurement';
import { IWoodCut } from '@models/IWoodCut';
import { IEdgeBandings, ISetEdgeBandingProps } from '@models/IEdgeBanding';

export type IWoodReducer = {
  projectName: string;
  unitOfMeasurement: typeof UNIT_OF_MEASUREMENT_KEYS[number];
  freeVein: boolean;
  cutName: string | null;
  width: string | null;
  widthWithError: boolean;
  height: string | null;
  heightWithError: boolean;
  amount: number;
  edgeBanding: IEdgeBandings;
  cuts: IWoodCut[];
  cutIndexToEdit: number | null;
  formAction: 'update' | 'add' | 'first-add';
  formIsValid: boolean;
};

const initialState: IWoodReducer = {
  projectName: '',
  unitOfMeasurement: 'centimeters',
  freeVein: false,
  cutName: null,
  width: null,
  widthWithError: false,
  height: null,
  heightWithError: false,
  edgeBanding: {
    top: null,
    left: null,
    bottom: null,
    right: null,
  },
  amount: 1,
  cuts: [],
  cutIndexToEdit: null,
  formAction: 'first-add',
  formIsValid: false,
};

export const woodSlice = createSlice({
  name: 'wood',
  initialState,
  reducers: {
    setProjectName: (state, action: PayloadAction<IWoodReducer['projectName']>) => {
      state.projectName = action.payload;
    },
    setUnitOfMeasurement: (state, action: PayloadAction<IWoodReducer['unitOfMeasurement']>) => {
      state.unitOfMeasurement = action.payload;
    },
    setFreeVein: (state, action: PayloadAction<IWoodReducer['freeVein']>) => {
      state.freeVein = action.payload;
    },
    setCutName: (state, action: PayloadAction<IWoodReducer['cutName']>) => {
      state.cutName = action.payload;
    },
    setWidth: (state, action: PayloadAction<IWoodReducer['width']>) => {
      state.width = action.payload;
    },
    setWidthWithError: (state, action: PayloadAction<IWoodReducer['widthWithError']>) => {
      state.widthWithError = action.payload;
    },
    setHeight: (state, action: PayloadAction<IWoodReducer['height']>) => {
      state.height = action.payload;
    },
    setHeightWithError: (state, action: PayloadAction<IWoodReducer['heightWithError']>) => {
      state.heightWithError = action.payload;
    },
    setAmount: (state, action: PayloadAction<IWoodReducer['amount']>) => {
      state.amount = action.payload;
    },
    setEdgeBanding: (state, action: PayloadAction<ISetEdgeBandingProps>) => {
      state.edgeBanding[action.payload.side] = action.payload.value;
    },
    addCut: (state) => {
      const { unitOfMeasurement, freeVein, cutName, width, height, amount, edgeBanding } = state;
      if (cutName != null && width != null && height != null) {
        if (state.cutIndexToEdit !== null) {
          //update wood cut
          state.cuts[state.cutIndexToEdit] = {
            unitOfMeasurement,
            freeVein,
            cutName,
            width,
            height,
            amount,
            edgeBanding,
          };
          state.cutIndexToEdit = null;
        } else {
          //create wood cut
          state.cuts.push({
            unitOfMeasurement,
            freeVein,
            cutName,
            width,
            height,
            amount,
            edgeBanding,
          });
        }
      }
      state.unitOfMeasurement = initialState.unitOfMeasurement;
      state.freeVein = initialState.freeVein;
      state.cutName = initialState.cutName;
      state.width = initialState.width;
      state.widthWithError = initialState.widthWithError;
      state.height = initialState.height;
      state.heightWithError = initialState.heightWithError;
      state.amount = initialState.amount;
      state.edgeBanding = initialState.edgeBanding;
      state.formIsValid = initialState.formIsValid;
      state.formAction = 'add';
    },
    clearFormValues: (state) => {
      state.cutIndexToEdit = null;
      state.unitOfMeasurement = initialState.unitOfMeasurement;
      state.freeVein = initialState.freeVein;
      state.cutName = initialState.cutName;
      state.width = initialState.width;
      state.widthWithError = initialState.widthWithError;
      state.height = initialState.height;
      state.heightWithError = initialState.heightWithError;
      state.amount = initialState.amount;
      state.edgeBanding = initialState.edgeBanding;
      state.formIsValid = initialState.formIsValid;
      state.formAction = 'add';
    },
    setFormIsValid: (state, action: PayloadAction<IWoodReducer['formIsValid']>) => {
      state.formIsValid = action.payload;
    },
    setCutIndexToEdit: (state, action: PayloadAction<IWoodReducer['cutIndexToEdit']>) => {
      state.cutIndexToEdit = action.payload;
      if (action.payload !== null) {
        const cutToEdit = state.cuts[action.payload];
        state.unitOfMeasurement = cutToEdit.unitOfMeasurement;
        state.freeVein = cutToEdit.freeVein;
        state.cutName = cutToEdit.cutName;
        state.width = cutToEdit.width;
        state.widthWithError = initialState.widthWithError;
        state.height = cutToEdit.height;
        state.heightWithError = initialState.heightWithError;
        state.amount = cutToEdit.amount;
        state.edgeBanding = cutToEdit.edgeBanding;
        state.formIsValid = initialState.formIsValid;
        state.formAction = 'update';
      }
    },
    setCuts: (state, action: PayloadAction<IWoodReducer['cuts']>) => {
      state.cuts = action.payload;
    },
  },
});

// Export actions
export const {
  setProjectName,
  setUnitOfMeasurement,
  setFreeVein,
  setCutName,
  setWidth,
  setWidthWithError,
  setHeight,
  setHeightWithError,
  setAmount,
  addCut,
  setFormIsValid,
  setCuts,
  setCutIndexToEdit,
  clearFormValues,
  setEdgeBanding,
} = woodSlice.actions;

// Definición de selectores
export const getProjectName = (state: RootState) => state.wood.projectName;

export const getUnitOfMeasurement = (state: RootState) => state.wood.unitOfMeasurement;

export const getFreeVein = (state: RootState) => state.wood.freeVein;

export const getCutName = (state: RootState) => state.wood.cutName;

export const getWidth = (state: RootState) => state.wood.width;

export const getWidthWithError = (state: RootState) => state.wood.widthWithError;

export const getHeight = (state: RootState) => state.wood.height;

export const getHeightWithError = (state: RootState) => state.wood.heightWithError;

export const getAmount = (state: RootState) => state.wood.amount;

export const getFormIsValid = (state: RootState) => state.wood.formIsValid;

export const getFormAction = (state: RootState) => state.wood.formAction;

export const getCuts = (state: RootState) => state.wood.cuts;

export const getEdgeBanding = (state: RootState) => state.wood.edgeBanding;

export const getCutIndexToEdit = (state: RootState) => state.wood.cutIndexToEdit;

export default woodSlice.reducer;
