import { FC } from 'react';
import { useAppSelector } from '@app/hooks';
import { SubTitle } from './styles';
import CutList from '../../components/CutList';
import { getStatus } from '@redux/OptimizationSlice';
import Loading from '@globalComponents/Loading';
import { Description } from '@globalComponents/Text/Description';
import { useTranslation } from 'react-i18next';

const CuttingList: FC = () => {
  const { t } = useTranslation();
  const status = useAppSelector(getStatus);

  return (
    <div data-testid="cutting-list">
      {status == 'loading' && <Loading data-testid="spinner" />}
      <Description>{t('CHECK_CUTS_DESCRIPTION')}</Description>
      <SubTitle>{t('CUTS_DETAILS')}</SubTitle>
      <CutList />
    </div>
  );
};

export default CuttingList;
