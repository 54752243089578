import React from 'react';
import Options from './components/Options';

const ConfirmTypeOfWood: React.FC = () => {
  return (
    <div data-testid="confirm-type-of-wood">
      <Options />
    </div>
  );
};

export default ConfirmTypeOfWood;
