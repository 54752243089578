import { Button } from '@digital-retail/journey-ui-kit';
import { SidebarWrapperStyled } from '@features/CuttingConfiguration/styles';
import { closeModal } from '@globalUtils/closeModal';
import { useTranslation } from 'react-i18next';

const SuccessfulSendEmailSidebar: React.FC = () => {
  const { t } = useTranslation();
  const goOut = () => {
    closeModal();
  };

  return (
    <SidebarWrapperStyled>
      <Button data-testid="exit-button" variant="ghost" onClick={goOut}>
        {t('EXIT')}
      </Button>
    </SidebarWrapperStyled>
  );
};

export default SuccessfulSendEmailSidebar;
