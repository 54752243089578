import { useAppSelector } from '@app/hooks';
import { getCurrentPosition } from '@redux/StepperSlice';
import Layout from '@globalComponents/Layout';
import { getHeader, getMain, getSidebar } from './utils';
import { VIEWS } from '@globalUtils/constants/views';

const CustomRouter = () => {
  const position = useAppSelector(getCurrentPosition);
  const MainComponent = getMain({ position, VIEWS });
  const SidebarComponent = getSidebar({ position, VIEWS });
  const HeaderComponent = getHeader({ position, VIEWS });

  return (
    <Layout
      data-testid="custom-router"
      withStepper={false}
      header={HeaderComponent !== null ? <HeaderComponent /> : <></>}
      sidebar={SidebarComponent !== null ? <SidebarComponent /> : <></>}
    >
      <MainComponent />
    </Layout>
  );
};

export default CustomRouter;
