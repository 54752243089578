import { BASE_COLOR } from '@globalUtils/constants/baseColor';
import { mediaQuery } from '@globalUtils/mediaQuery';
import styled from 'styled-components';

export const SidebarWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  padding: 8px 16px 16px 16px;
  width: 100%;
  box-sizing: border-box;

  ${mediaQuery.mobileOnly(`
    flex-direction: column-reverse;
    padding: 8px 16px;
  `)}
`;

export const SectionSubtitleStyled = styled.div`
  margin-bottom: 20px;
  padding: 11px 16px;
  background-color: #f5f5f5;
`;
export const TextWrapperStyled = styled.div`
  padding: 0 16px;
  margin-bottom: 21px;
  margin-top: 19px;
`;

export const TextBoardPosition = styled.p`
  text-transform: lowercase;
  font-size: 18px;
  & span {
    font-size: 14px;
    padding-left: 5px;
  }
`;

export const ActionBoardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-left: -3px;
  margin-right: -3px;
  & button {
    padding: 3px 0;
    font-size: 14px;
    &:not(:disabled) {
      &:hover,
      &:active,
      &:focus {
        color: ${BASE_COLOR.primary};
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
`;

export const WoodQuantityDescriptionStyled = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 21px;
`;

export const WoodDetailWrapperStyled = styled.div`
  display: inline-flex;
  margin-bottom: 17px;
  padding: 0 14px;
  & .wood-detail {
    padding: 0 8px;
  }
  & .wood-image {
    width: 76px;
    height: 76px;
    padding: 4px;
    border: 1px solid #d8d8d8;
    justify-content: center;
  }
`;

export const WoodImageStyled = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const NameItemStyled = styled.p`
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 4px;
`;

export const WoodDescriptionStyled = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-top: 0;
  margin-bottom: 1px;
`;

export const SkuStyled = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  color: #949494;
`;

export const PlaneWrapperStyled = styled.div`
  padding: 0 21px 25px 21px;
  width: initial;
`;

type FooterBoardProps = {
  hasEdgeBandings: boolean;
};

export const FooterBoard = styled.div<FooterBoardProps>`
  display: flex;
  justify-content: ${({ hasEdgeBandings }) => (hasEdgeBandings ? 'space-between' : 'center')};
  align-items: center;
  padding-top: 5px;
`;

export const BetaSwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & p {
    padding-right: 10px;
  }
`;
export const EdgeBandingIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & p {
    padding-left: 5px;
  }
`;

export const Price = styled.p`
  height: 32px;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  margin-top: 1px;
  margin-bottom: 9px;
`;

export const AdviceDescription = styled.p`
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  margin: 0 0 25px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
`;
