/* istanbul ignore file */

import { useEffect, useState } from 'react';

type UseLoadMoreOnScrollProps = {
  scroller: any;
  minDelta?: number;
};

export const useLoadMoreOnScroll = (props: UseLoadMoreOnScrollProps) => {
  const { scroller, minDelta = 25 } = props;

  if (!scroller) {
    throw new Error(
      `Cannot use useLoadMoreOnScroll without specifying a scroller. \n scroller MUST be an HtmlDOMElement acquired via useRef() or React.ref()`
    );
  }

  // Indicator for more data
  const [checkData, setCheckData] = useState(true);
  // Scroll direction
  const [scrollDirection, setScrollDirection] = useState('none');
  // Distance to reach the lower edge of the box
  const [delta, setDelta] = useState(Infinity);
  // Current Scroll position
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const scrollElement = scroller.current;
    if (scrollDirection === 'down') {
      setDelta(
        scrollElement.scrollHeight -
          scrollElement.scrollTop -
          scrollElement.getBoundingClientRect().height
      );
    }
  }, [scrollTop, scrollDirection, checkData]);

  useEffect(() => {
    const scrollElement = scroller.current;
    let previousScroll = 0;
    // console.log(`scroll listener added to element`, scrollElement);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const scrollHandler = scrollElement.addEventListener('scroll', (_e: any) => {
      setScrollTop(scrollElement.scrollTop);
      if (previousScroll < scrollElement.scrollTop) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
      previousScroll = scrollElement.scrollTop;
    });

    return () => {
      // console.log(`scroll listener removed from element`, scrollElement);
      scrollElement.removeEventListener('scroll', scrollHandler);
    };
  }, [scroller]);

  useEffect(() => {
    // console.log(`delta value ${delta} and scroll direction ${scrollDirection}`);
    if (delta <= minDelta && !checkData) {
      setCheckData(true);
    }
  }, [delta]);

  return { checkData, setCheckData };
};
