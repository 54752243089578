/* istanbul ignore file */

import { BREAKPOINTS } from '@globalUtils/constants/breakpoints';

const generateMediaWithMin = (
  children: string,
  minWidth: number
) => `@media (min-width: ${minWidth}px) {
  ${children}
}`;

const generateMediaWithMinAndMax = (
  children: string,
  minWidth: number,
  maxWidth: number
) => `@media (min-width: ${minWidth}px) and (max-width:${maxWidth}px) {
  ${children}
}`;

export const mediaQuery = {
  /**
   *
   * @param children
   * @returns
   */
  fallback: (children: string) => generateMediaWithMinAndMax(children, 0, BREAKPOINTS.mobile - 1),

  /**
   * Media query creator method for devices that are "Mobile".
   * @param children
   * @returns
   */
  mobileOnly: (children: string) =>
    generateMediaWithMinAndMax(children, BREAKPOINTS.mobile, BREAKPOINTS.tablet - 1),
  /**
   * Media query creator method for devices from "Mobile" to "Tablet", including the latter.
   * @param children
   * @returns
   */
  mobileToTablet: (children: string) =>
    generateMediaWithMinAndMax(children, BREAKPOINTS.mobile, BREAKPOINTS.smallDesktop - 1),

  /**
   * Media query creator method for devices from "Mobile" to "Small Desktop", including the latter.
   * @param children
   * @returns
   */
  mobileToSmallDesktop: (children: string) =>
    generateMediaWithMinAndMax(children, BREAKPOINTS.mobile, BREAKPOINTS.largerDesktop - 1),

  /**
   * Media query creator method for devices from "Mobile" up.
   * @param children
   * @returns
   */
  mobileToUp: (children: string) => generateMediaWithMin(children, BREAKPOINTS.mobile),

  /**
   * Media query creator method for devices that are "Tablet".
   * @param children
   * @returns
   */
  tabletOnly: (children: string) =>
    generateMediaWithMinAndMax(children, BREAKPOINTS.tablet, BREAKPOINTS.smallDesktop - 1),

  /**
   * Media query creator method for devices from "Tablet" to "Small Desktop", including the latter.
   * @param children
   * @returns
   */
  tabletToSmallDesktop: (children: string) =>
    generateMediaWithMinAndMax(children, BREAKPOINTS.tablet, BREAKPOINTS.largerDesktop - 1),

  /**
   * Media query creator method for devices from "Tablet" up.
   * @param children
   * @returns
   */
  tabletToUp: (children: string) => generateMediaWithMin(children, BREAKPOINTS.tablet),

  /**
   * Media query creator method for devices that are "Small Desktop".
   * @param children
   * @returns
   */
  smallDesktopOnly: (children: string) =>
    generateMediaWithMinAndMax(children, BREAKPOINTS.smallDesktop, BREAKPOINTS.largerDesktop - 1),

  /**
   * Media query creator method for devices from "Small Desktop" up.
   * @param children
   * @returns
   */
  smallDesktopToUp: (children: string) => generateMediaWithMin(children, BREAKPOINTS.smallDesktop),

  /**
   * Media query creator method for devices from "Larger Desktop" up.
   * @param children
   * @returns
   */
  largerDesktopToUp: (children: string) =>
    generateMediaWithMin(children, BREAKPOINTS.largerDesktop),
} as const;
