import { useAppSelector } from '@app/hooks';
import { getFormAction } from '@redux/WoodSlice';
import { FC } from 'react';
import CuttingForm from './components/CuttingForm';
import { Description } from '@globalComponents/Text/Description';
import { useTranslation } from 'react-i18next';

const CuttingConfiguration: FC = () => {
  const { t } = useTranslation();
  const formAction = useAppSelector(getFormAction);

  return (
    <div data-testid="cutting-configuration">
      <Description>
        {formAction === 'update' ? t('UPDATE_CUT_DESCRIPTION') : t('ENTER_CUT_DESCRIPTION')}
      </Description>
      <CuttingForm />
    </div>
  );
};

export default CuttingConfiguration;
