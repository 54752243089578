import { mediaQuery } from '@globalUtils/mediaQuery';
import styled from 'styled-components';

export const OptionsStyled = styled.div`
  overflow: auto;
  display: flex;
  align-self: stretch;
  gap: 5px;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 0 16px;
  position: relative;
`;

type OptionStyledProps = {
  active: boolean;
};

export const OptionStyled = styled.div<OptionStyledProps>`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-rows: 1fr;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content;
  gap: 19px;
  grid-template-areas: 'productImage productInformation';
  padding: 5px;
  cursor: pointer;
  border: ${(props) => (props.active ? '2px solid #0683e4' : 'none')};

  ${mediaQuery.mobileOnly(`
  gap: 11px;
    
  `)}
`;

export const ProductImageStyled = styled.div`
  grid-area: productImage;
  width: 85px;
  height: 75px;
  position: relative;
  text-align: center;

  img {
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const ProductInformationStyled = styled.div`
  grid-area: productInformation;
`;

export const ProductBrandStyled = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 4px;

  color: #595959;
`;

export const ProductNameStyled = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;

  color: #333333;
`;

export const ProductPriceStyled = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;

  color: #333333;
`;

export const LoadingStyled = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #595959;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  ${OptionStyled} + & {
    position: relative;
    top: unset;
    left: unset;
    text-align: center;
    transform: initial;
  }
`;

export const ErrorStyled = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #595959;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`;
