import { HeaderWrapperStyled, TitleStyled } from '@globalComponents/Layout/styles';
import { useTranslation } from 'react-i18next';

const CuttingResultHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <HeaderWrapperStyled>
      <TitleStyled>{t('CHECK_CUTS')}</TitleStyled>
    </HeaderWrapperStyled>
  );
};

export default CuttingResultHeader;
