/* istanbul ignore file */
import { mediaQuery } from '@globalUtils/mediaQuery';
import styled from 'styled-components';
import { gradientProps } from '..';

export const HeaderStyled = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  min-height: 0;
  z-index: 1;
  background-color: #ffffff;
`;

export const MainStyled = styled.div`
  width: 100%;
`;

export const SidebarStyled = styled.div`
  left: 0;
  right: 0;
  position: fixed;
  width: 100%;
  min-height: 20px;
  z-index: 200;
  background-color: #ffffff;
  padding: 0;
  bottom: 0;
`;

export const TitleStyled = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  padding: 8px 16px 0 16px;

  ${mediaQuery.mobileOnly(`
    font-size: 20px;
  `)}
`;

export const HeaderWrapperStyled = styled.div`
  padding-bottom: 3px;
`;

export const GradientBorderBottom = styled.div<gradientProps>`
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  position: fixed;
  left: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  height: ${({ visible }) => (visible ? '20px' : 0)};
  transition: all 0.4s ease-out;
  z-index: 10;
`;

export const GradientBorderTop = styled.div<gradientProps>`
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  position: fixed;
  left: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  height: ${({ visible }) => (visible ? '20px' : 0)};
  transition: all 0.4s ease-out;
  z-index: 100;
`;
