import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export type ICuttingListReducer = {
  showCuttingActionsId: number | null;
};

const initialState: ICuttingListReducer = {
  showCuttingActionsId: null,
};

export const cuttingListSlice = createSlice({
  name: 'cuttingList',
  initialState,
  reducers: {
    setShowCuttingActionsId: (
      state,
      action: PayloadAction<ICuttingListReducer['showCuttingActionsId']>
    ) => {
      state.showCuttingActionsId = action.payload;
    },
  },
});

//Export actions
export const { setShowCuttingActionsId } = cuttingListSlice.actions;

//Definición de selectores
export const getShowCuttingActionsId = (state: RootState) => state.cuttingList.showCuttingActionsId;

export default cuttingListSlice.reducer;
