import { useAppSelector } from '@app/hooks';
import { useScroll } from '@hooks/useScroll';
import { useWindowSize } from '@hooks/useWindowSize';
import { getSelectedProduct } from '@redux/ConfirmTypeOfWoodSlice';
import { getCurrentPosition } from '@redux/StepperSlice';
import { FC, useEffect, useRef, useState } from 'react';
import {
  GradientBorderBottom,
  GradientBorderTop,
  HeaderStyled,
  MainStyled,
  SidebarStyled,
} from './styles';

type MainWithSidebarLayoutProps = {
  sidebar: React.ReactNode;
  withStepper: boolean;
  header: React.ReactNode;
};

export type gradientProps = {
  visible: boolean;
};

const MainWithSidebarLayout: FC<MainWithSidebarLayoutProps> = ({
  children: main,
  sidebar,
  header,
}) => {
  const sidebarComponent = useRef<HTMLDivElement>(null);
  const headerComponent = useRef<HTMLDivElement>(null);
  const selectedProduct = useAppSelector(getSelectedProduct);

  const position = useAppSelector(getCurrentPosition);
  const { width, height } = useWindowSize();
  const { scrollableTop, scrollableBottom } = useScroll(position, width, height);

  const [sidebarHeight, setSidebarHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    setSidebarHeight(sidebarComponent.current?.clientHeight || 0);
    setHeaderHeight(headerComponent.current?.clientHeight || 0);
  }, [position, width, height, selectedProduct]);

  return (
    <div data-testid="layout-main-with-sidebar">
      <HeaderStyled ref={headerComponent} data-testid="header">
        {header}
      </HeaderStyled>

      <GradientBorderTop style={{ top: headerHeight }} visible={scrollableTop} />
      <GradientBorderBottom style={{ bottom: sidebarHeight }} visible={scrollableBottom} />

      <MainStyled
        style={{ paddingTop: headerHeight, paddingBottom: sidebarHeight }}
        data-testid="main"
      >
        {main}
      </MainStyled>

      <SidebarStyled ref={sidebarComponent} data-testid="sidebar">
        {sidebar}
      </SidebarStyled>
    </div>
  );
};

export default MainWithSidebarLayout;
