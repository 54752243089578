import styled from 'styled-components';

export const TableStyled = styled.div`
  padding: 0 16px 16px 16px;
`;

export const TableHeaderStyled = styled.div`
  padding: 6px;
`;

export const TableBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 6px;

  & > div {
    flex: 1 1 auto;
  }
`;

export const CutRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 44px;
  align-content: stretch;
  align-items: stretch;
  font-size: 14px;
  margin: -6px;
  /* user-select: none; */

  ${TableHeaderStyled} & {
    height: 32px;
  }

  ${TableBodyStyled} & {
    font-weight: bold;
  }
`;

export const ColForIdStyled = styled.div`
  flex: 0 1 40px;
  padding: 0 6px;
  display: flex;
  align-items: center;
`;

export const ColForNameStyled = styled.div`
  flex: 2 1 100px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
`;

export const ColForAmountStyled = styled.div`
  flex: 1 1 40px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
`;

export const ColForSizeStyled = styled.div`
  flex: 1 1 80px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
`;

export const ColForShowActionStyled = styled.div`
  flex: 0 1 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
`;

export const ColForDeleteActionStyled = styled.div`
  color: red;
  flex: 1 1 auto;
  display: flex;
  gap: 6px;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 3px 0 0 3px;
`;

export const ColForEditActionStyled = styled.div`
  flex: 1 1 auto;
  display: flex;
  gap: 6px;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  background-color: #f8f8f8;
`;

export const ColForHideActionStyled = styled.div`
  padding-left: 5px;
  position: relative;
  flex: 0 1 30px;
  display: flex;
  align-items: center;
  border-left: 1px solid #bfbfbf;

  &::after {
    content: '';
    left: -1px;
    position: absolute;
    width: 5px;
    height: 10px;
    background-color: #f8f8f8;

    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #bfbfbf;
    box-sizing: border-box;
    border-left: 0;
  }
`;

export const CutStyled = styled.div`
  &.show-actions {
    ${ColForNameStyled}, ${ColForAmountStyled}, ${ColForSizeStyled}, ${ColForShowActionStyled} {
      display: none;
    }
  }

  &:not(.show-actions) {
    ${ColForDeleteActionStyled}, ${ColForEditActionStyled}, ${ColForHideActionStyled} {
      display: none;
    }
  }
`;
