/* istanbul ignore file */

import { IProduct } from '../../models/IProduct';

const woodServiceUrl = process.env.REACT_APP_WOOD_SERVICE_URL || '';

type FetchProductListResponse = {
  pagination: {
    page: number;
    pageSize: number;
    totalPages: number;
    totalProducts: number;
  };
  results: IProduct[];
};

export type GetPaginatedProductsProps = {
  tenant: string;
  priceGroup: string;
  page: number;
  pageSize: number;
};

export const getPaginatedProducts = async ({
  tenant,
  priceGroup,
  page,
  pageSize,
}: GetPaginatedProductsProps): Promise<FetchProductListResponse> => {
  const url = `${woodServiceUrl}/searches/${tenant}/resizable?priceGroup=${priceGroup}&page=${page}&pageSize=${pageSize}`;

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        return data.data;
      }
      return Promise.reject(response.status);
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export type GetProductProps = {
  tenant: string;
  priceGroup: string;
  productId: string;
};

export const getProduct = async ({
  tenant,
  priceGroup,
  productId,
}: GetProductProps): Promise<IProduct> => {
  const url = `${woodServiceUrl}/searches/${tenant}/resizable/${productId}?priceGroup=${priceGroup}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        return data.data;
      }
      return Promise.reject(response.status);
    })
    .catch((error) => {
      throw new Error(error);
    });
};
