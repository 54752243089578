import styled from 'styled-components';

export const Description = styled.p`
  font-weight: normal;
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 20px;
  padding: 0 16px;
`;

export const InputWrapper = styled.div`
  padding: 0 16px 35px 16px;
`;

export const Label = styled.p`
  color: #333333;
  font-weight: bold;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 2px;
`;

export const ButtonsWrappers = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: right;
  padding: 0;
  padding: 0 16px 16px 16px;
  box-sizing: border-box;
`;
