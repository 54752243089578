import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Button } from '@digital-retail/journey-ui-kit';
import { BREAKPOINTS } from '@globalUtils/constants/breakpoints';
import { CutsRequestFormat } from '@globalUtils/CutsRequestFormat';
import { useWindowSize } from '@hooks/useWindowSize';
import { getTenant } from '@redux/ConfigSlice';
import { getSelectedProduct } from '@redux/ConfirmTypeOfWoodSlice';
import { getEdgeBandingList } from '@redux/CuttingConfigurationSlice';
import { setShowCuttingActionsId } from '@redux/CuttingListSlice';
import { calculateCutsAsync } from '@redux/OptimizationSlice';
import { nextPosition, previousPosition } from '@redux/StepperSlice';
import { getCuts, getProjectName } from '@redux/WoodSlice';
import { useTranslation } from 'react-i18next';
import { SidebarWrapperStyled } from '../../styles';

const CuttingListSidebar = () => {
  const { t } = useTranslation();
  const cuts = useAppSelector(getCuts);
  const projectName = useAppSelector(getProjectName);
  const edgeBandingList = useAppSelector(getEdgeBandingList);
  const selectedProduct = useAppSelector(getSelectedProduct);
  const tenant = useAppSelector(getTenant).toLowerCase();

  const dispatch = useAppDispatch();
  const widthScreen = useWindowSize().width || 0;

  const handleClickNewCut = () => {
    dispatch(previousPosition());
  };

  const handleClickCalculateCuts = async () => {
    if (selectedProduct) {
      const dataOptimization = CutsRequestFormat(
        cuts,
        selectedProduct,
        projectName,
        edgeBandingList
      );
      const res = await dispatch(calculateCutsAsync({ data: dataOptimization, tenant }));
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(nextPosition());
        dispatch(setShowCuttingActionsId(null));
      }
    }
  };

  return (
    <SidebarWrapperStyled>
      <Button
        data-testid="new-cut"
        onClick={handleClickNewCut}
        variant="notTransactional"
        outline
        fullWidth={widthScreen < BREAKPOINTS.tablet}
      >
        + {t('ADD_CUT')}
      </Button>
      <Button
        data-testid="calculate-cuts-button"
        disabled={cuts.length === 0}
        fullWidth={widthScreen < BREAKPOINTS.tablet}
        onClick={() => handleClickCalculateCuts()}
      >
        {t('CALCULATE_CUTS')}
      </Button>
    </SidebarWrapperStyled>
  );
};

export default CuttingListSidebar;
