import styled from 'styled-components';
import { mediaQuery } from '@globalUtils/mediaQuery';

export const LabelStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-bottom: 2px;
  width: max-content;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  padding-left: 2px;
  line-height: 20px;

  span {
    margin-right: 5px;
  }
`;

export const CuttingFormStyled = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  align-items: flex-start;
`;

export const UnitOfMeasurementInputStyled = styled.div`
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 16px;
`;

export const BoxLeftStyled = styled.div`
  flex: 0 0 auto;
  padding-right: 8px;
  width: calc((100% / 3) * 2 - 8px);
  margin-bottom: 16px;

  ${mediaQuery.mobileOnly(`
    width: calc(60% - 8px);
  `)}
`;

export const BoxRightStyled = styled.div`
  flex: 0 0 auto;
  padding-left: 8px;
  width: calc((100% / 3) - 8px);
  margin-bottom: 16px;

  ${mediaQuery.mobileOnly(`
    width: calc(40% - 8px);
  `)}
`;

export const FreeVeinInputStyled = styled.div`
  flex: 0 0 auto;
  padding-left: 8px;
  padding-right: 8px;
  width: calc((100% / 2) - 16px);
  align-self: flex-end;

  margin-bottom: 7px;

  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: max-content max-content max-content;
  grid-template-rows: 1fr;
  grid-template-areas: '. .';
  gap: 10px;

  & ${LabelStyled} {
    margin-bottom: 0;
  }
`;

export const FreeVeinHelperStyled = styled.div`
  margin-left: 10px;
`;

export const FreeVeinHelperContentStyled = styled.div`
  margin-bottom: 13px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: max-content;

  span {
    color: #0072ce;
    font-size: 14px;
    line-height: 20px;
    margin-right: 5px;
  }
`;

export const PopoverContentStyled = styled.div`
  text-align: initial;
  font-size: 14px;
  line-height: 20px;
`;

export const EdgeBandingContainer = styled.div`
  display: flex;
  gap: 15px;
`;
