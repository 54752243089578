/* istanbul ignore file */
import { useEffect, useState } from 'react';

export const useScroll = (position, width, height) => {
  const [scrollableTop, setScrollableTop] = useState(false);
  const [scrollableBottom, setScrollableBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollableTop(window.scrollY != 0);
      setScrollableBottom(window.innerHeight + window.scrollY < document.body.offsetHeight);
    };
    setScrollableTop(window.scrollY != 0);
    setScrollableBottom(window.innerHeight + window.scrollY < document.body.offsetHeight);
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [position, width, height]);
  return { scrollableTop, scrollableBottom };
};
