import React from 'react';
import { HeaderWrapperStyled, TitleStyled } from '@globalComponents/Layout/styles';
import { useTranslation } from 'react-i18next';

const ConfirmTypeOfWoodHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <HeaderWrapperStyled data-testid="confirm-type-of-wood-header">
      <TitleStyled>{t('CONFIRM_WOOD')}</TitleStyled>
    </HeaderWrapperStyled>
  );
};

export default ConfirmTypeOfWoodHeader;
