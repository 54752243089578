import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Button } from '@digital-retail/journey-ui-kit';
import { SidebarWrapperStyled } from '@features/CuttingConfiguration/styles';
import { getTenant } from '@redux/ConfigSlice';
import { getOptimizationResult } from '@redux/OptimizationSlice';
import {
  getCustomerEmail,
  getCustomerName,
  getStatusNotification,
  sendProjectEmailAsync,
} from '@redux/SendProjectEmailSlice';
import { nextPosition, previousPosition } from '@redux/StepperSlice';
import { getProjectName } from '@redux/WoodSlice';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const SendProjectEmailSidebar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const customerEmail = useAppSelector(getCustomerEmail);
  const customerName = useAppSelector(getCustomerName);
  const emailRegex = /\S+@\S+\.\S+/;
  const pdfUrl = useAppSelector(getOptimizationResult)?.url_planos;
  const projectName = useAppSelector(getProjectName);
  const statusNotification = useAppSelector(getStatusNotification);
  const currentTenant = useAppSelector(getTenant);

  const handleClickSendEmail = () => {
    dispatch(
      sendProjectEmailAsync({
        email: customerEmail,
        name: customerName,
        pdf_url: pdfUrl || '',
        project_name: projectName,
        tenant: currentTenant,
      })
    );
  };

  useEffect(() => {
    if (statusNotification === 'success') {
      dispatch(nextPosition());
    }
  }, [statusNotification]);

  const goBack = () => {
    dispatch(previousPosition());
  };

  return (
    <SidebarWrapperStyled>
      <Button data-testid="back-button" variant="ghost" onClick={() => goBack()}>
        {t('GO_BACK')}
      </Button>
      <Button
        data-testid="send-email-button"
        disabled={customerName.length === 0 || !emailRegex.test(customerEmail)}
        onClick={() => handleClickSendEmail()}
      >
        {t('SEND')}
      </Button>
    </SidebarWrapperStyled>
  );
};

export default SendProjectEmailSidebar;
