import { IProduct } from '@models/IProduct';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { currencyFormat } from '@globalUtils/currencyFormat';
import { getPriceGroup, getTenant } from '@redux/ConfigSlice';
import {
  getPagination,
  getProductList,
  getResizableBoardsAsync,
  getSelectedProduct,
  getStatusBoard,
  getStatusBoards,
  setSelectedProduct,
} from '@redux/ConfirmTypeOfWoodSlice';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  ErrorStyled,
  LoadingStyled,
  OptionsStyled,
  OptionStyled,
  ProductBrandStyled,
  ProductImageStyled,
  ProductInformationStyled,
  ProductNameStyled,
  ProductPriceStyled,
} from './styles';
import { useLoadMoreOnScroll } from '@globalUtils/useLoadMoreOnScroll';
import { useTranslation } from 'react-i18next';

const Options: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const statusBoards = useAppSelector(getStatusBoards);
  const statusBoard = useAppSelector(getStatusBoard);
  const productList = useAppSelector(getProductList);
  const selectedProduct = useAppSelector(getSelectedProduct);
  const tenant = useAppSelector(getTenant);
  const priceGroup = useAppSelector(getPriceGroup);
  const pageSize = 10;
  const scroller = useRef<HTMLDivElement>(null);
  const pagination = useAppSelector(getPagination);
  const { checkData, setCheckData } = useLoadMoreOnScroll({
    scroller,
  });

  const processedProducts = useMemo(
    () => productList.filter((product) => product.price.priceWithoutFormatting !== 0),
    [productList]
  );
  const weHaveAError = useMemo(
    () => pagination.page !== 0 && processedProducts.length === 0,
    [pagination, processedProducts]
  );

  useEffect(() => {
    if (
      tenant !== '' &&
      checkData &&
      (pagination.page === 0 || pagination.page !== pagination.totalPages)
    ) {
      dispatch(
        getResizableBoardsAsync({ tenant, priceGroup, page: pagination.page + 1, pageSize })
      ).finally(() => {
        setCheckData(false);
      });
    }
  }, [checkData, tenant]);

  const handleClickProduct = (product: IProduct) => {
    dispatch(setSelectedProduct(product));
  };

  return (
    <OptionsStyled
      data-testid="options"
      ref={scroller}
      style={{ height: selectedProduct ? '380px' : '450px' }}
    >
      {processedProducts.map(
        (product, idx) =>
          product.price.priceWithoutFormatting !== 0 && (
            <OptionStyled
              key={idx}
              onClick={() => handleClickProduct(product)}
              active={selectedProduct?.id === product.id}
            >
              <ProductImageStyled>
                <img src={product.imageURL} />
              </ProductImageStyled>
              <ProductInformationStyled>
                <ProductBrandStyled>{product.brandID}</ProductBrandStyled>
                <ProductNameStyled>{product.name}</ProductNameStyled>
                <ProductPriceStyled>
                  {currencyFormat({
                    number: product.price.priceWithoutFormatting,
                    currencySymbol: product.price.currencySymbol,
                    thousandSeparator: product.price.thousandSeparator,
                    decimalSeparator: product.price.decimalSeparator,
                    precision: product.price.precision,
                  })}{' '}
                  {product.price.unit}
                </ProductPriceStyled>
              </ProductInformationStyled>
            </OptionStyled>
          )
      )}
      {(statusBoards === 'loading' || statusBoard === 'loading') && (
        <LoadingStyled>{t('LOADING_BOARDS')}</LoadingStyled>
      )}
      {weHaveAError && (
        <ErrorStyled data-testid="message-error">{t('NO_BOARDS_MESSAGE')}</ErrorStyled>
      )}
    </OptionsStyled>
  );
};

export default Options;
