import React from 'react';
import { Description } from '@globalComponents/Text/Description';
import { useTranslation } from 'react-i18next';

const SuccessfulSendEmail: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="success-send-project-email">
      <Description>{t('PROJECT_SEND_DESCRIPTION')}</Description>
    </div>
  );
};

export default SuccessfulSendEmail;
