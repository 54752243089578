/* istanbul ignore file */
import { useAppSelector } from '@app/hooks';
import { useWindowSize } from '@hooks/useWindowSize';
import { getSelectedProduct } from '@redux/ConfirmTypeOfWoodSlice';
import { getBoardPosition, getOptimizationResult, getVisibleVein } from '@redux/OptimizationSlice';
import { getCuts } from '@redux/WoodSlice';
import { useRef, useEffect, useState, CanvasHTMLAttributes } from 'react';
import { CanvasContainer } from './styles';
import { drawBase, drawBoardEdge, drawVein, drawCuts } from './utils';

type CanvasProps = CanvasHTMLAttributes<HTMLCanvasElement>;

const Canvas = ({ ...props }: CanvasProps) => {
  const heightCanvasContainer = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [widthContainer, setWidthContainer] = useState(0);
  const { width, height } = useWindowSize();
  const dataDraw = useAppSelector(getOptimizationResult);
  const selectedProduct = useAppSelector(getSelectedProduct);
  const boardPosition = useAppSelector(getBoardPosition);
  const visibleVein = useAppSelector(getVisibleVein);
  const cuts = useAppSelector(getCuts);

  useEffect(() => {
    setWidthContainer(heightCanvasContainer.current?.clientWidth || 0);
  }, [widthContainer, width, height]);

  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const boardHeight = selectedProduct?.attributes.height || 0;
    const boardWidth = selectedProduct?.attributes.width || 0;
    const heightPX = Math.round((boardWidth * widthContainer) / boardHeight);

    if (!canvas?.getContext || dataDraw == null) return;

    const ctx = canvas.getContext('2d');
    const { cortes } = dataDraw.planos_corte[boardPosition];
    const initialCoordinateX = 0;
    const initialCoordinateY = 0;
    const adjustmentFactorForX = canvas.width / boardHeight;
    const adjustmentFactorForY = adjustmentFactorForX;

    if (ctx !== null) {
      drawBase({
        ctx,
        initialCoordinateX,
        initialCoordinateY,
        boardHeight,
        boardWidth,
        adjustmentFactorForX,
        adjustmentFactorForY,
      });
      if (visibleVein) {
        drawVein({
          ctx,
          initialCoordinateX,
          initialCoordinateY,
          boardHeight,
          boardWidth,
          adjustmentFactorForX,
          adjustmentFactorForY,
        });
      }
      drawCuts({
        ctx,
        cortes,
        cortesRx: cuts,
        initialCoordinateX,
        initialCoordinateY,
        adjustmentFactorForX,
        adjustmentFactorForY,
      });
      drawBoardEdge({
        ctx,
        initialCoordinateX,
        initialCoordinateY,
        boardHeight,
        boardWidth,
        adjustmentFactorForX,
        adjustmentFactorForY,
      });

      if (canvasHeight === 0 || canvasWidth === 0) {
        setCanvasHeight(heightPX);
        setCanvasWidth(widthContainer);
      }
    }
  }, [widthContainer, canvasHeight, boardPosition, visibleVein]);

  return (
    <CanvasContainer ref={heightCanvasContainer}>
      <canvas {...props} height={canvasHeight} width={canvasWidth} ref={canvasRef} />
    </CanvasContainer>
  );
};

export default Canvas;
