import currency from 'currency.js';

type CurrencyFormatProps = {
  number: number;
  currencySymbol: string;
  thousandSeparator: string;
  decimalSeparator: string;
  precision: number;
};

export const currencyFormat = ({
  number,
  currencySymbol,
  thousandSeparator: separator,
  decimalSeparator: decimal,
  precision,
}: CurrencyFormatProps) => {
  return currency(number, {
    symbol: `${currencySymbol} `,
    separator,
    decimal,
    precision,
  }).format();
};
