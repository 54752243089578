import { emailNotification } from '@api/emailNotification';
import { RootState } from '@app/store';
import { IEmailNotificationProps, IEmailNotificationResponse } from '@models/IEmailNotification';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ISendProjectEmailReducer = {
  notificationId: string;
  customerName: string;
  customerEmail: string;

  status: 'idle' | 'loading' | 'success' | 'failed';
};

const initialState: ISendProjectEmailReducer = {
  notificationId: '',
  customerName: '',
  customerEmail: '',

  status: 'idle',
};

export const sendProjectEmailAsync = createAsyncThunk(
  'sendProjectEmail/sendProjectEmailAsync',
  async (data: IEmailNotificationProps) => {
    const result = await emailNotification(data);
    return result;
  }
);

export const sendProjectEmailSlice = createSlice({
  name: 'sendProjectEmail',
  initialState,
  reducers: {
    setCustomerName: (state, action: PayloadAction<ISendProjectEmailReducer['customerName']>) => {
      state.customerName = action.payload;
    },
    setCustomerEmail: (state, action: PayloadAction<ISendProjectEmailReducer['customerEmail']>) => {
      state.customerEmail = action.payload;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(sendProjectEmailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        sendProjectEmailAsync.fulfilled,
        (state, action: PayloadAction<IEmailNotificationResponse>) => {
          state.status = 'success';
          state.notificationId = action.payload?.data.notification.id;
        }
      )
      .addCase(sendProjectEmailAsync.rejected, (state) => {
        state.status = 'failed';
        state.notificationId = '';
      });
  },
});

// Export actions
export const { setCustomerName, setCustomerEmail } = sendProjectEmailSlice.actions;

// Definición de selectores
export const getStatusNotification = (state: RootState) => state.sendProjectEmail.status;

export const getCustomerName = (state: RootState) => state.sendProjectEmail.customerName;

export const getCustomerEmail = (state: RootState) => state.sendProjectEmail.customerEmail;

export const getNotificationId = (state: RootState) => state.sendProjectEmail.notificationId;

export default sendProjectEmailSlice.reducer;
