import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Popover, PopoverContainer, ListGroup, Toggle } from '@digital-retail/journey-ui-kit';
import {
  getActiveTooltip,
  getEdgeBandingList,
  toggleTooltip,
} from '@redux/CuttingConfigurationSlice';
import { ReactComponent as RightArrow } from '@assets/icons/right-arrow.svg';
import { ReactComponent as SuccessIcon } from '@assets/icons/success.svg';
import { EdgeBandingContainer, EdgeBandingIcon, EdgeBandingImage, ThicknessButton } from './styles';
import { useWindowSize } from '@hooks/useWindowSize';
import { BREAKPOINTS } from '@globalUtils/constants/breakpoints';
import { IEdgeBanding } from '@models/IEdgeBanding';
import { getEdgeBanding, setEdgeBanding } from '@redux/WoodSlice';
import { TOOLTIPS_SIDES_EDGEBANDING } from '@globalUtils/constants/tooltipList';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type IEdgeBandingButtonProps = {
  side: 'top' | 'right' | 'bottom' | 'left';
};

const EdgeBandingButton: React.FC<IEdgeBandingButtonProps> = ({ side }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const edgeBandingList = useAppSelector(getEdgeBandingList);
  const edgeBanding = useAppSelector(getEdgeBanding);
  const widthScreen = useWindowSize().width || 0;
  const activeTooltip = useAppSelector(getActiveTooltip);
  const isNone = edgeBanding[side] === null;

  const handleClickThicknessButton = (edgeBanding: IEdgeBanding | null) => {
    dispatch(setEdgeBanding({ side, value: edgeBanding }));
    setTimeout(() => dispatch(toggleTooltip(null)), 500);
  };

  const handleToggleEdgeBandingTooltip = (side: IEdgeBandingButtonProps['side']) => {
    dispatch(toggleTooltip(TOOLTIPS_SIDES_EDGEBANDING[side]));
  };

  return (
    <PopoverContainer data-testid="edge-banding">
      <Toggle active={edgeBanding[side] !== null}>
        <EdgeBandingImage
          data-testid="edge-banding-button"
          className={clsx(`${side}`)}
          onClick={() => handleToggleEdgeBandingTooltip(side)}
        >
          {edgeBanding[side]?.thickness}
        </EdgeBandingImage>
      </Toggle>
      <Popover
        align={'right'}
        visible={activeTooltip === TOOLTIPS_SIDES_EDGEBANDING[side]}
        size={widthScreen > BREAKPOINTS.tablet ? 290 : 213}
        style={{ transition: 'linear 200ms, opacity 200ms' }}
      >
        <EdgeBandingContainer>
          <ListGroup>
            <ListGroup.Item active={isNone}>
              <ThicknessButton
                onClick={() => handleClickThicknessButton(null)}
                className={clsx({ active: isNone })}
                data-testid="none-thickness-button"
              >
                {t('NONE')}
                <EdgeBandingIcon>{isNone ? <SuccessIcon /> : <RightArrow />}</EdgeBandingIcon>
              </ThicknessButton>
            </ListGroup.Item>
            {edgeBandingList.map((e) => {
              const isActive = edgeBanding[side]?.id === e.id;
              return (
                <ListGroup.Item key={e.id} active={isActive}>
                  <ThicknessButton
                    onClick={() => handleClickThicknessButton(e)}
                    className={clsx({ active: isActive })}
                    data-testid="thickness-buttons"
                  >
                    {e.label}
                    <EdgeBandingIcon>{isActive ? <SuccessIcon /> : <RightArrow />}</EdgeBandingIcon>
                  </ThicknessButton>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </EdgeBandingContainer>
      </Popover>
    </PopoverContainer>
  );
};

export default EdgeBandingButton;
