import React from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Button } from '@digital-retail/journey-ui-kit';
import { ButtonsWrappers } from '@features/ProjectName/styles';
import { nextPosition } from '@redux/StepperSlice';
import { getProjectName, setProjectName } from '@redux/WoodSlice';
import { useTranslation } from 'react-i18next';

const ProjectNameSidebar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectName = useAppSelector(getProjectName);

  const onSetSuggestedProjectName = () => {
    dispatch(setProjectName(t('PROJECT_NAME_EXAMPLE')));
  };

  const onContinue = () => {
    dispatch(nextPosition());
  };

  return (
    <ButtonsWrappers data-testid="sidebar-content">
      <Button
        onClick={() => onSetSuggestedProjectName()}
        variant="ghost"
        data-testid="button-auto-fill"
      >
        {t('SELECT_FOR_ME')}
      </Button>
      <Button
        disabled={projectName.length === 0}
        style={{ width: 139, marginLeft: 10 }}
        onClick={() => onContinue()}
        data-testid="forward"
      >
        {t('GO_FORWARD')}
      </Button>
    </ButtonsWrappers>
  );
};

export default ProjectNameSidebar;
