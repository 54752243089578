import { Spinner } from '@digital-retail/journey-ui-kit';
import { LoadingStyled } from './styles';

const Loading = () => (
  <LoadingStyled>
    <Spinner />
  </LoadingStyled>
);

export default Loading;
