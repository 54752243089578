import { Button } from '@digital-retail/journey-ui-kit';
import { SidebarWrapperStyled } from '@features/ProjectDownload/styles';
import { closeModal } from '@globalUtils/closeModal';
import { BREAKPOINTS } from '@globalUtils/constants/breakpoints';
import { useWindowSize } from '@hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

const ProjectDownloadSidebar: React.FC = () => {
  const { t } = useTranslation();
  const widthScreen = useWindowSize().width || 0;

  const handleClickExit = () => {
    closeModal();
  };

  return (
    <SidebarWrapperStyled>
      <Button
        data-testid="exit-button"
        variant="notTransactional"
        outline
        fullWidth={widthScreen < BREAKPOINTS.tablet}
        onClick={handleClickExit}
      >
        {t('EXIT')}
      </Button>
    </SidebarWrapperStyled>
  );
};

export default ProjectDownloadSidebar;
