/* istanbul ignore file */

import { IView } from '@models/IView';

// ConfirmTypeOfWood
import ConfirmTypeOfWoodMain from '@features/ConfirmTypeOfWood';
import ConfirmTypeOfWoodHeader from '@features/ConfirmTypeOfWood/components/ConfirmTypeOfWoodHeader';
import ConfirmTypeOfWoodSidebar from '@features/ConfirmTypeOfWood/components/ConfirmTypeOfWoodSidebar';

// Project Name
import ProjectNameMain from '@features/ProjectName';
import ProjectNameHeader from '@features/ProjectName/components/ProjectNameHeader';
import ProjectNameSidebar from '@features/ProjectName/components/ProjectNameSidebar';

// Cutting Configuration
import CuttingConfigurationMain from '@features/CuttingConfiguration';
import CuttingConfigurationHeader from '@features/CuttingConfiguration/components/CuttingConfigurationHeader';
import CuttingConfigurationSidebar from '@features/CuttingConfiguration/components/CuttingConfigurationSidebar';

// Cutting List
import CuttingList from '@features/CuttingList';
import CuttingListHeader from '@features/CuttingList/components/CuttingListHeader';
import CuttingListSidebar from '@features/CuttingList/components/CuttingListSidebar';

// Cutting result
import CuttingResult from '@features/CuttingResult';
import CuttingResultHeader from '@features/CuttingResult/components/CuttingResultHeader';
import CuttingResultSidebar from '@features/CuttingResult/components/CuttingResultSidebar';

// Download project
import DownloadProject from '@features/ProjectDownload';
import ProjectDownloadHeader from '@features/ProjectDownload/components/ProjectDownloadHeader';
import ProjectDownloadSidebar from '@features/ProjectDownload/components/ProjectDownloadSidebar';

// Send project email
import SendProjectEmail from '@features/SendProjectEmail';
import SendProjectEmailHeader from '@features/SendProjectEmail/components/SendProjectEmailHeader';
import SendProjectEmailSidebar from '@features/SendProjectEmail/components/SendProjectEmailSidebar';

// Successful Send email
import SuccessfulSendEmail from '@features/SuccessfulSendEmail';
import SuccessfulSendEmailHeader from '@features/SuccessfulSendEmail/components/SuccessfulSendEmailHeader';
import SuccessfulSendEmailSidebar from '@features/SuccessfulSendEmail/components/SuccessfulSendEmailSidebar';

export const VIEWS: IView[] = [
  {
    title: 'ConfirmTypeOfWoodMain',
    header: ConfirmTypeOfWoodHeader,
    main: ConfirmTypeOfWoodMain,
    sidebar: ConfirmTypeOfWoodSidebar,
  },
  {
    title: 'ProjectName',
    header: ProjectNameHeader,
    main: ProjectNameMain,
    sidebar: ProjectNameSidebar,
  },
  {
    title: 'CuttingConfiguration',
    header: CuttingConfigurationHeader,
    main: CuttingConfigurationMain,
    sidebar: CuttingConfigurationSidebar,
  },
  {
    title: 'CuttingList',
    header: CuttingListHeader,
    main: CuttingList,
    sidebar: CuttingListSidebar,
  },
  {
    title: 'CuttingResult',
    header: CuttingResultHeader,
    main: CuttingResult,
    sidebar: CuttingResultSidebar,
  },
  {
    title: 'DownloadProject',
    header: ProjectDownloadHeader,
    main: DownloadProject,
    sidebar: ProjectDownloadSidebar,
  },
  {
    title: 'SendProjectEmail',
    header: SendProjectEmailHeader,
    main: SendProjectEmail,
    sidebar: SendProjectEmailSidebar,
  },
  {
    title: 'SuccessfulSendEmail',
    header: SuccessfulSendEmailHeader,
    main: SuccessfulSendEmail,
    sidebar: SuccessfulSendEmailSidebar,
  },
];
