import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { IStatusAsyncThunk } from '@models/IStatusAsyncThunk';
import { IProduct } from '@models/IProduct';
import {
  GetPaginatedProductsProps,
  getPaginatedProducts,
  GetProductProps,
  getProduct,
} from '@api/woods';

export type IConfirmTypeOfWoodReducer = {
  statusBoards: IStatusAsyncThunk;
  statusBoard: IStatusAsyncThunk;
  productList: IProduct[];
  pagination: {
    page: number;
    pageSize: number;
    totalPages: number;
    totalProducts: number;
  };
  selectedProduct: IProduct | null;
};

const initialState: IConfirmTypeOfWoodReducer = {
  statusBoards: 'idle',
  statusBoard: 'idle',
  productList: [],
  pagination: {
    page: 0,
    pageSize: 0,
    totalPages: 0,
    totalProducts: 0,
  },
  selectedProduct: null,
};

export const getResizableBoardsAsync = createAsyncThunk(
  'confirmTypeOfWood/getResizableBoardsAsync',
  async ({ tenant, priceGroup, page, pageSize }: GetPaginatedProductsProps) => {
    const result = await getPaginatedProducts({ tenant, priceGroup, page, pageSize });
    return result;
  }
);

export const getSelectedBoardAsync = createAsyncThunk(
  'confirmTypeOfWood/getSelectedBoardAsync',
  async ({ tenant, priceGroup, productId }: GetProductProps) => {
    const result = await getProduct({ tenant, priceGroup, productId });
    return result;
  }
);

export const confirmTypeOfWoodSlice = createSlice({
  name: 'confirmTypeOfWood',
  initialState,
  reducers: {
    setSelectedProduct: (
      state,
      action: PayloadAction<IConfirmTypeOfWoodReducer['selectedProduct']>
    ) => {
      state.selectedProduct = action.payload;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(getResizableBoardsAsync.pending, (state) => {
        state.statusBoards = 'loading';
      })
      .addCase(getResizableBoardsAsync.fulfilled, (state, action) => {
        state.statusBoards = 'idle';
        state.productList = [...state.productList, ...action.payload.results];
        state.pagination = action.payload.pagination;
      })
      .addCase(getResizableBoardsAsync.rejected, (state) => {
        state.statusBoards = 'failed';
      })
      .addCase(getSelectedBoardAsync.pending, (state) => {
        state.statusBoards = 'loading';
      })
      .addCase(getSelectedBoardAsync.fulfilled, (state, action) => {
        state.statusBoards = 'idle';
        state.selectedProduct = action.payload;
      })
      .addCase(getSelectedBoardAsync.rejected, (state) => {
        state.statusBoards = 'failed';
      });
  },
});

// Export actions
export const { setSelectedProduct } = confirmTypeOfWoodSlice.actions;

//Definición de selectores
export const getProductList = (state: RootState) => state.confirmTypeOfWood.productList;
export const getStatusBoards = (state: RootState) => state.confirmTypeOfWood.statusBoards;
export const getStatusBoard = (state: RootState) => state.confirmTypeOfWood.statusBoard;
export const getSelectedProduct = (state: RootState) => state.confirmTypeOfWood.selectedProduct;
export const getPagination = (state: RootState) => state.confirmTypeOfWood.pagination;

export default confirmTypeOfWoodSlice.reducer;
