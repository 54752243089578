export const sendEmailVisibleVars = {
  socl: 'REACT_APP_SOCL_SEND_EMAIL_VISIBLE',
  soco: 'REACT_APP_SOCO_SEND_EMAIL_VISIBLE',
  sope: 'REACT_APP_SOPE_SEND_EMAIL_VISIBLE',
  souy: 'REACT_APP_SOUY_SEND_EMAIL_VISIBLE',
  soar: 'REACT_APP_SOAR_SEND_EMAIL_VISIBLE',
  somx: 'REACT_APP_SOMX_SEND_EMAIL_VISIBLE',
  sobr: 'REACT_APP_SOBR_SEND_EMAIL_VISIBLE',
  facl: 'REACT_APP_FACL_SEND_EMAIL_VISIBLE',
  faco: 'REACT_APP_FACO_SEND_EMAIL_VISIBLE',
  fape: 'REACT_APP_FAPE_SEND_EMAIL_VISIBLE',
  faar: 'REACT_APP_FAAR_SEND_EMAIL_VISIBLE',
};
