/* istanbul ignore file */

import { IEdgeBanding } from '@models/IEdgeBanding';
import { ICutOptimizationProps, IOptimizationCutResponse } from '@models/IOptimization';

const woodServiceUrl = process.env.REACT_APP_WOOD_SERVICE_URL || '';

export const calculateCuts = async (
  payload: ICutOptimizationProps,
  tenant: string
): Promise<IOptimizationCutResponse> => {
  const response = await fetch(`${woodServiceUrl}/cuts/optimizer?tenant=${tenant}`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();

  return data;
};

export const getEdgeBandings = async (tenant: string): Promise<IEdgeBanding[]> => {
  const response = await fetch(`${woodServiceUrl}/edgebandings/${tenant}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();

  return data.data;
};
