import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

import { calculateCuts } from '@api/lepton';
import { ICalculateCutsProps, IOptimizationCutResponse } from '@models/IOptimization';

export type IOptimizationReducer = {
  status: 'idle' | 'loading' | 'failed';
  cutResult: IOptimizationCutResponse | null;
  canvasVetaVisible: boolean;
  boardPosition: number;
  visibleVein: boolean;
  confirmedStatus: boolean;
};

const initialState: IOptimizationReducer = {
  status: 'idle',
  cutResult: null,
  canvasVetaVisible: false,
  boardPosition: 0,
  visibleVein: false,
  confirmedStatus: false,
};

export const calculateCutsAsync = createAsyncThunk(
  'wood/calculateCuts',
  async ({ data, tenant }: ICalculateCutsProps) => {
    const result = await calculateCuts(data, tenant);
    return result;
  }
);

export const woodSlice = createSlice({
  name: 'wood',
  initialState,
  reducers: {
    setBoardPosition: (state, action: PayloadAction<IOptimizationReducer['boardPosition']>) => {
      state.boardPosition = action.payload;
    },
    setVisibleVein: (state, action: PayloadAction<IOptimizationReducer['visibleVein']>) => {
      state.visibleVein = action.payload;
    },
    setConfirmedStatus: (state, action: PayloadAction<IOptimizationReducer['confirmedStatus']>) => {
      state.confirmedStatus = action.payload;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(calculateCutsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        calculateCutsAsync.fulfilled,
        (state, action: PayloadAction<IOptimizationCutResponse | null>) => {
          state.status = 'idle';
          state.cutResult = action.payload;
        }
      )
      .addCase(calculateCutsAsync.rejected, (state) => {
        state.status = 'failed';
        state.cutResult = null;
      });
  },
});

// Export actions
export const { setBoardPosition, setVisibleVein, setConfirmedStatus } = woodSlice.actions;

// Definición de selectores

export const getStatus = (state: RootState) => state.optimization.status;

export const getOptimizationResult = (state: RootState) => state.optimization.cutResult;

export const getBoardPosition = (state: RootState) => state.optimization.boardPosition;

export const getVisibleVein = (state: RootState) => state.optimization.visibleVein;

export const getConfirmedStatus = (state: RootState) => state.optimization.confirmedStatus;

export default woodSlice.reducer;
