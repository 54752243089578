import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import StepperSlice from '@redux/StepperSlice';
import WoodSlice from '@redux/WoodSlice';
import CuttingConfigurationSlice from '@redux/CuttingConfigurationSlice';
import CuttingListSlice from '@redux/CuttingListSlice';
import OptimizationSlice from '@redux/OptimizationSlice';
import ConfirmTypeOfWoodSlice from '@redux/ConfirmTypeOfWoodSlice';
import SendProjectEmailSlice from '@redux/SendProjectEmailSlice';
import ConfigReducer from '@redux/ConfigSlice';

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export const rootReducer = {
  config: ConfigReducer,
  stepper: StepperSlice,
  wood: WoodSlice,
  cuttingConfiguration: CuttingConfigurationSlice,
  cuttingList: CuttingListSlice,
  optimization: OptimizationSlice,
  confirmTypeOfWood: ConfirmTypeOfWoodSlice,
  sendProjectEmail: SendProjectEmailSlice,
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type InitialRootState = RecursivePartial<RootState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
