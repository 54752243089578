/* istanbul ignore file */

import vetaImage from '@assets/images/vetaH.svg';
import { IWoodCut } from '@models/IWoodCut';

const COLORS = {
  highlight: '#78b4e6',
  highlightEdgebanding: '#FF3D3D',
  backgroundForCutouts: '#ffffff',
  backgroundForBase: '#f5f5f5',
  cutId: '#000000',
} as const;

type DrawBoardEdgeProps = {
  ctx: CanvasRenderingContext2D;
  initialCoordinateX: number;
  initialCoordinateY: number;
  boardHeight: number;
  boardWidth: number;
  adjustmentFactorForX: number;
  adjustmentFactorForY: number;
};
export const drawBoardEdge = ({
  ctx,
  initialCoordinateX,
  initialCoordinateY,
  boardHeight,
  boardWidth,
  adjustmentFactorForX,
  adjustmentFactorForY,
}: DrawBoardEdgeProps) => {
  ctx.lineWidth = 3;
  ctx.strokeStyle = COLORS.highlight;
  const width = boardHeight * adjustmentFactorForX;
  const height = boardWidth * adjustmentFactorForY;
  const radius = 6;

  ctx.beginPath();
  ctx.moveTo(initialCoordinateX + radius, initialCoordinateY);
  ctx.arcTo(
    initialCoordinateX + width,
    initialCoordinateY,
    initialCoordinateX + width,
    initialCoordinateY + height,
    radius
  );
  ctx.arcTo(
    initialCoordinateX + width,
    initialCoordinateY + height,
    initialCoordinateX,
    initialCoordinateY + height,
    radius
  );
  ctx.arcTo(
    initialCoordinateX,
    initialCoordinateY + height,
    initialCoordinateX,
    initialCoordinateY,
    radius
  );
  ctx.arcTo(
    initialCoordinateX,
    initialCoordinateY,
    initialCoordinateX + width,
    initialCoordinateY,
    radius
  );
  ctx.closePath();
  ctx.stroke();
};

type DrawBaseProps = {
  ctx: CanvasRenderingContext2D;
  initialCoordinateX: number;
  initialCoordinateY: number;
  boardHeight: number;
  boardWidth: number;
  adjustmentFactorForX: number;
  adjustmentFactorForY: number;
};
export const drawBase = ({
  ctx,
  initialCoordinateX,
  initialCoordinateY,
  boardHeight,
  boardWidth,
  adjustmentFactorForX,
  adjustmentFactorForY,
}: DrawBaseProps) => {
  ctx.fillStyle = COLORS.backgroundForBase;
  ctx.fillRect(
    initialCoordinateX,
    initialCoordinateY,
    boardHeight * adjustmentFactorForX,
    boardWidth * adjustmentFactorForY
  );
};

type DrawCutsProps = {
  ctx: CanvasRenderingContext2D;
  cortes: any[];
  cortesRx: IWoodCut[];
  initialCoordinateX: number;
  initialCoordinateY: number;
  adjustmentFactorForX: number;
  adjustmentFactorForY: number;
};
export const drawCuts = ({
  ctx,
  cortes,
  cortesRx,
  initialCoordinateX,
  initialCoordinateY,
  adjustmentFactorForX,
  adjustmentFactorForY,
}: DrawCutsProps) => {
  const numberOfCuts = cortes.length;
  const gap = 4;
  for (let i = 0; i < numberOfCuts; ++i) {
    const corte = cortes[i];
    const cutId = corte.id_corte;
    const corteRx = cortesRx[cutId - 1];

    if (cutId <= 0) continue;

    const xCoordinate = initialCoordinateX + corte.posicion.base * adjustmentFactorForX;
    const yCoordinate = initialCoordinateY + corte.posicion.altura * adjustmentFactorForY;
    const width = corte.dimOriginal.base * adjustmentFactorForX;
    const height = corte.dimOriginal.altura * adjustmentFactorForY;

    // draw the inside of the pieces
    ctx.fillStyle = COLORS.backgroundForCutouts;
    ctx.fillRect(xCoordinate, yCoordinate, width, height);

    // ctx.lineWidth = 1;
    // ctx.strokeStyle = 'rgb(170,170,170)';
    // ctx.strokeRect(x, y, base, altura);

    // draw the lines of the highlights
    ctx.lineWidth = 1;
    ctx.strokeStyle = COLORS.highlight;
    ctx.strokeRect(xCoordinate, yCoordinate, width, height);

    // draw Edgebandings
    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = COLORS.highlightEdgebanding;
    ctx.setLineDash([5, 5]);
    if (corteRx.edgeBanding.bottom) {
      // bottom
      ctx.moveTo(xCoordinate + gap, yCoordinate + height - gap);
      ctx.lineTo(xCoordinate + width - gap, yCoordinate + height - gap);
    }
    if (corteRx.edgeBanding.top) {
      // top
      ctx.moveTo(xCoordinate + gap, yCoordinate + gap);
      ctx.lineTo(xCoordinate + width - gap, yCoordinate + gap);
    }
    if (corteRx.edgeBanding.right) {
      // right
      ctx.moveTo(xCoordinate + width - gap, yCoordinate + gap);
      ctx.lineTo(xCoordinate + width - gap, yCoordinate + height - gap);
    }
    if (corteRx.edgeBanding.left) {
      // left
      ctx.moveTo(xCoordinate + gap, yCoordinate + gap);
      ctx.lineTo(xCoordinate + gap, yCoordinate + height - gap);
    }
    ctx.stroke();
    ctx.restore();

    // draw curt identifications
    ctx.font = '14px Times New Roman';
    ctx.textBaseline = 'top';
    ctx.textAlign = 'center';
    ctx.fillStyle = COLORS.cutId;
    ctx.fillText(String(cutId), xCoordinate + width / 2, yCoordinate + height / 2 - 7);
  }
};

type DrawVeinProps = {
  ctx: CanvasRenderingContext2D;
  initialCoordinateX: number;
  initialCoordinateY: number;
  boardHeight: number;
  boardWidth: number;
  adjustmentFactorForX: number;
  adjustmentFactorForY: number;
};
export const drawVein = ({
  ctx,
  initialCoordinateX,
  initialCoordinateY,
  boardHeight,
  boardWidth,
  adjustmentFactorForX,
  adjustmentFactorForY,
}: DrawVeinProps) => {
  const image = new Image();
  image.onload = () => {
    ctx?.drawImage(
      image,
      initialCoordinateX,
      initialCoordinateY,
      boardHeight * adjustmentFactorForX,
      boardWidth * adjustmentFactorForY
    );
  };
  image.src = vetaImage;
};
