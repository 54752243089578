import styled from 'styled-components';

export const LoadingStyled = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
  justify-content: center;
  align-items: center;
  display: flex;
`;
