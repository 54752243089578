import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Button } from '@digital-retail/journey-ui-kit';
import { SidebarWrapperStyled } from '@features/CuttingResult/styles';
import { BREAKPOINTS } from '@globalUtils/constants/breakpoints';
import { useWindowSize } from '@hooks/useWindowSize';
import { getConfirmedStatus } from '@redux/OptimizationSlice';
import { nextPosition, previousPosition } from '@redux/StepperSlice';
import { useTranslation } from 'react-i18next';

const CuttingResultSidebar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const confirmedStatus = useAppSelector(getConfirmedStatus);
  const widthScreen = useWindowSize().width || 0;

  const goBack = () => {
    dispatch(previousPosition());
  };
  const handleClickForward = () => {
    dispatch(nextPosition());
  };

  return (
    <SidebarWrapperStyled>
      <Button
        data-testid="back-button"
        variant="ghost"
        onClick={() => goBack()}
        fullWidth={widthScreen < BREAKPOINTS.tablet}
      >
        {t('GO_BACK')}
      </Button>
      <Button
        disabled={!confirmedStatus}
        data-testid="download-button"
        onClick={() => handleClickForward()}
        fullWidth={widthScreen < BREAKPOINTS.tablet}
      >
        {t('DOWNLOAD_RESULTS')}
      </Button>
    </SidebarWrapperStyled>
  );
};

export default CuttingResultSidebar;
