import { mediaQuery } from '@globalUtils/mediaQuery';
import styled from 'styled-components';

export const DescriptionStyled = styled.div`
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 28px;
  padding: 0 16px;
`;

export const SidebarWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  padding: 0 16px 16px 16px;

  & button {
    ${mediaQuery.mobileOnly(`
    justify-content: space-evenly;
    width: 100%;
  `)}
  }
`;
