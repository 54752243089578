/* istanbul ignore file */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import esCommon from './translations/es/common.json';
import ptCommon from './translations/pt/common.json';

i18n.use(initReactI18next).init({
  resources: {
    es: {
      translation: esCommon,
    },
    pt: {
      translation: ptCommon,
    },
  },
  debug: true,
  lng: 'es',
  fallbackLng: ['es', 'pt'],
});

export default i18n;
