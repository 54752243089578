import { mediaQuery } from '@globalUtils/mediaQuery';
import styled from 'styled-components';

export const DescriptionStyled = styled.div`
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 23px;
  padding: 0 16px;

  ${mediaQuery.mobileOnly(`
    font-size: 14px;
    margin-bottom: 17px;
  `)}
`;
