import { useAppSelector } from '@app/hooks';
import { HeaderWrapperStyled, TitleStyled } from '@globalComponents/Layout/styles';
import { getFormAction } from '@redux/WoodSlice';
import { useTranslation } from 'react-i18next';

const CuttingConfigurationHeader: React.FC = () => {
  const { t } = useTranslation();
  const formAction = useAppSelector(getFormAction);
  return (
    <HeaderWrapperStyled>
      <TitleStyled>{formAction === 'update' ? t('UPDATE_CUT') : t('ENTER_CUT')}</TitleStyled>
    </HeaderWrapperStyled>
  );
};

export default CuttingConfigurationHeader;
