/* istanbul ignore file */

import { IBreakPoint } from '../../models/IBreakPoint';

export const BREAKPOINTS: IBreakPoint = {
  // the minimum size mobile in 0 to avoid errors in future integrations with the iframe in modal
  mobile: 0,
  tablet: 420,
  smallDesktop: 1024,
  largerDesktop: 1280,
};
