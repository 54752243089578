import { mediaQuery } from '@globalUtils/mediaQuery';
import styled from 'styled-components';

export const SubTitleStyled = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 2px;

  ${mediaQuery.mobileOnly(`
    font-size: 17px;
  `)}
`;
