import { IView } from '@models/IView';
import { FC } from 'react';

type getProps = {
  position: number;
  VIEWS: IView[];
};
export const getMain = ({ position, VIEWS }: getProps): FC => VIEWS[position].main;

export const getSidebar = ({ position, VIEWS }: getProps): FC | null => VIEWS[position].sidebar;

export const getHeader = ({ position, VIEWS }: getProps): FC | null => VIEWS[position].header;
