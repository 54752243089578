import {
  CutRowStyled,
  ColForNameStyled,
  ColForAmountStyled,
  ColForSizeStyled,
  ColForShowActionStyled,
  TableStyled,
  TableHeaderStyled,
  TableBodyStyled,
  ColForIdStyled,
  CutStyled,
  ColForDeleteActionStyled,
  ColForEditActionStyled,
  ColForHideActionStyled,
} from './styles';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete-icon.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close-icon.svg';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getCuts, setCutIndexToEdit, setCuts } from '@redux/WoodSlice';
import { setPosition } from '@redux/StepperSlice';
import { Highlight, Meatballs, Toggle } from '@digital-retail/journey-ui-kit';
import { createRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type CutListProps = {
  idVisible?: boolean;
};

const CutList = ({ idVisible = false }: CutListProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const cuts = useAppSelector(getCuts);
  const refs = useMemo(() => cuts.map(() => createRef<any>()), [cuts]);

  const hideAllActions = () => {
    refs.map((ref) => ref.current.classList.remove('show-actions'));
  };

  const handleClickMore = (id: number) => {
    refs[id].current.classList.toggle('show-actions');
  };

  const handleClickDeleteCut = (id: number) => {
    const temp = [...cuts];
    temp.splice(id, 1);
    dispatch(setCuts(temp));
    hideAllActions();
    if (temp.length === 0) {
      dispatch(setPosition(2));
    }
  };

  const handleClickEditCut = (id: number) => {
    dispatch(setCutIndexToEdit(id));
    dispatch(setPosition(2));
  };

  return (
    <TableStyled>
      <TableHeaderStyled>
        <CutRowStyled>
          {idVisible && <ColForIdStyled>ID</ColForIdStyled>}
          <ColForNameStyled>{t('CUT_NAME')}</ColForNameStyled>
          <ColForAmountStyled>{t('QTY_SHORT')}</ColForAmountStyled>
          <ColForSizeStyled>{t('SIZE')} (mm)</ColForSizeStyled>
          <ColForShowActionStyled />
        </CutRowStyled>
      </TableHeaderStyled>
      <TableBodyStyled data-testid="cut-list">
        {cuts.map((cut, idx) => (
          <CutStyled key={idx} ref={refs[idx]}>
            <Toggle active={true}>
              <CutRowStyled>
                {idVisible && <ColForIdStyled>{idx + 1}</ColForIdStyled>}
                <ColForNameStyled>{cut.cutName}</ColForNameStyled>
                <ColForAmountStyled>{cut.amount}</ColForAmountStyled>
                <ColForSizeStyled>
                  {cut.unitOfMeasurement === 'millimeters'
                    ? `${cut.width} x ${cut.height}`
                    : `${parseFloat(cut.width || '') * 10} x ${parseFloat(cut.height || '') * 10}`}
                </ColForSizeStyled>
                <ColForShowActionStyled
                  onClick={() => handleClickMore(idx)}
                  data-testid={`open-cut-actions-button-${idx}`}
                >
                  <Meatballs />
                </ColForShowActionStyled>
                <ColForDeleteActionStyled
                  onClick={() => handleClickDeleteCut(idx)}
                  data-testid={`delete-cut-button-${idx}`}
                >
                  <DeleteIcon /> <span>{t('DELETE')}</span>
                </ColForDeleteActionStyled>
                <ColForEditActionStyled
                  onClick={() => handleClickEditCut(idx)}
                  data-testid={`edit-cut-button-${idx}`}
                >
                  <EditIcon />{' '}
                  <Highlight variant="notTransactional">
                    <span>{t('EDIT')}</span>
                  </Highlight>
                </ColForEditActionStyled>
                <ColForHideActionStyled
                  onClick={() => handleClickMore(idx)}
                  data-testid={`open-cut-actions-button-${idx}`}
                >
                  <CloseIcon />
                </ColForHideActionStyled>
              </CutRowStyled>
            </Toggle>
          </CutStyled>
        ))}
      </TableBodyStyled>
    </TableStyled>
  );
};

export default CutList;
