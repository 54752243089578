import { mediaQuery } from '@globalUtils/mediaQuery';
import styled from 'styled-components';

export const DescriptionStyled = styled.p`
  font-weight: normal;
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 20px;
  padding: 0 16px;
`;

export const SidebarWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  padding: 8px 16px 16px 16px;
  width: 100%;
  box-sizing: border-box;

  ${mediaQuery.mobileOnly(`
    padding: 8px 16px;
  `)}

  ${mediaQuery.tabletToUp(`
    & button {
      padding: 11px 57px;
    }
  `)}
`;

export const OptionStyled = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-rows: 1fr;
  grid-template-columns: max-content 1fr;
  grid-template-rows: repeat(2, max-content);
  gap: 0;
  grid-template-areas:
    'optionIcon optionTitle'
    'optionIcon optionDescription';
  justify-items: stretch;
  align-items: stretch;

  padding: 18px 8px;
  cursor: pointer;
`;

export const OptionTitleStyled = styled.div`
  grid-area: optionTitle;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

export const OptionDescriptionStyled = styled.div`
  grid-area: optionDescription;
`;

export const OptionIconStyled = styled.div`
  grid-area: optionIcon;
  padding-right: 8px;
`;

export const OptionsStyled = styled.div`
  padding: 0 16px;

  & ${OptionStyled} + ${OptionStyled} {
    border-top: 1px solid #e6e8ed;
  }
`;
