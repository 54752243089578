import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  Input,
  OnChangeInputEvent,
  Popover,
  PopoverContainer,
  InputSpinner,
  OnChangeInputSpinnerEvent,
  Radio,
  OnChangeRadioEvent,
  RadioOption,
  Switch,
  OnChangeSwitchEvent,
} from '@digital-retail/journey-ui-kit';
import {
  getAmount,
  getCutName,
  getFreeVein,
  getHeight,
  getHeightWithError,
  getUnitOfMeasurement,
  getWidth,
  getWidthWithError,
  setAmount,
  setCutName,
  setFreeVein,
  setHeight,
  setUnitOfMeasurement,
  setWidth,
  setHeightWithError,
  setWidthWithError,
  setFormIsValid,
} from '@redux/WoodSlice';
import { FC, useEffect } from 'react';
import {
  CuttingFormStyled,
  FreeVeinHelperContentStyled,
  FreeVeinHelperStyled,
  FreeVeinInputStyled,
  BoxRightStyled,
  LabelStyled,
  UnitOfMeasurementInputStyled,
  BoxLeftStyled,
  PopoverContentStyled,
  EdgeBandingContainer,
} from './styles';
import { ReactComponent as QuestionIcon } from '@icons/question-icon.svg';
import { ReactComponent as InfoIcon } from '@icons/info-icon.svg';
import {
  getEdgeBandingList,
  getEdgeBandingsAsync,
  getActiveTooltip,
  toggleTooltip,
} from '@redux/CuttingConfigurationSlice';
import { UNITS_OF_MEASUREMENT } from '@globalUtils/constants/unitsOfMeasurement';
import { useWindowSize } from '@hooks/useWindowSize';
import { BREAKPOINTS } from '@globalUtils/constants/breakpoints';
import { PROPORTIONS_OF_MILLIMETERS_TO_OTHER_MEASURES } from '@globalUtils/constants/proportionsOfMillimetersToOtherMeasures';
import { getValueAccordingToFactor } from '@globalUtils/getValueAccordingToFactor';
import { TRIMMED } from '@globalUtils/constants/trimmed';
import EdgeBandingButton from '../EdgeBandingButton';
import { TOOLTIP_LIST } from '@globalUtils/constants/tooltipList';
import { getSelectedProduct } from '@redux/ConfirmTypeOfWoodSlice';
import { useTranslation } from 'react-i18next';
import { getTenant } from '@redux/ConfigSlice';

/**
 * Validation rules of measures in millimeters
 */
const MINIMUM_VALIDATION_RULES = {
  width: 300,
  height: 100,
} as const;

const CuttingForm: FC = () => {
  const { t } = useTranslation();
  const widthScreen = useWindowSize().width || 0;
  const dispatch = useAppDispatch();
  const unitOfMeasurement = useAppSelector(getUnitOfMeasurement);
  const freeVein = useAppSelector(getFreeVein);
  const cutName = useAppSelector(getCutName);
  const width = useAppSelector(getWidth);
  const widthWithError = useAppSelector(getWidthWithError);
  const height = useAppSelector(getHeight);
  const heightWithError = useAppSelector(getHeightWithError);
  const amount = useAppSelector(getAmount);
  const activeTooltip = useAppSelector(getActiveTooltip);
  const edgeBandings = useAppSelector(getEdgeBandingList);
  const selectedProduct = useAppSelector(getSelectedProduct);
  const factor = PROPORTIONS_OF_MILLIMETERS_TO_OTHER_MEASURES[unitOfMeasurement];
  const tenant = useAppSelector(getTenant).toLowerCase();

  const boardWidth = selectedProduct?.attributes.width || 0;
  const boardHeight = selectedProduct?.attributes.height || 0;

  const MAXIMUM_VALIDATION_RULES = {
    width: boardWidth - TRIMMED.width,
    height: boardHeight - TRIMMED.width,
  } as const;

  const validateWidthByUnitOfMeasurement = (unit: string) => {
    if (width !== null) {
      const value = parseFloat(width);

      dispatch(
        setWidthWithError(
          value <
            getValueAccordingToFactor(
              MINIMUM_VALIDATION_RULES.width,
              PROPORTIONS_OF_MILLIMETERS_TO_OTHER_MEASURES[unit]
            ) ||
            value >
              getValueAccordingToFactor(
                MAXIMUM_VALIDATION_RULES.width,
                PROPORTIONS_OF_MILLIMETERS_TO_OTHER_MEASURES[unit]
              )
        )
      );
    }
  };

  const validateHeightByUnitOfMeasurement = (unit: string) => {
    if (height !== null) {
      const value = parseFloat(height);

      dispatch(
        setHeightWithError(
          value <
            getValueAccordingToFactor(
              MINIMUM_VALIDATION_RULES.height,
              PROPORTIONS_OF_MILLIMETERS_TO_OTHER_MEASURES[unit]
            ) ||
            value >
              getValueAccordingToFactor(
                MAXIMUM_VALIDATION_RULES.height,
                PROPORTIONS_OF_MILLIMETERS_TO_OTHER_MEASURES[unit]
              )
        )
      );
    }
  };

  const handleBlurWidth = () => {
    validateWidthByUnitOfMeasurement(unitOfMeasurement);
  };

  const handleBlurHeight = () => {
    validateHeightByUnitOfMeasurement(unitOfMeasurement);
  };

  const handleChangeUnitOfMeasurement: OnChangeRadioEvent = (value) => {
    dispatch(setUnitOfMeasurement(value as typeof UNITS_OF_MEASUREMENT[number]['key']));

    validateWidthByUnitOfMeasurement(value);
    validateHeightByUnitOfMeasurement(value);
  };

  const handleChangeFreeVein: OnChangeSwitchEvent = (value) => {
    dispatch(setFreeVein(value));
  };

  const handleChangeCutName: OnChangeInputEvent = (value) => {
    dispatch(setCutName(value));
  };

  const handleChangeWidth: OnChangeInputEvent = (value) => {
    dispatch(setWidth(value));
  };

  const handleChangeHeight: OnChangeInputEvent = (value) => {
    dispatch(setHeight(value));
  };

  const handleChangeAmount: OnChangeInputSpinnerEvent = (value) => {
    dispatch(setAmount(value));
  };

  const handleClickFreeVeinHelper = () => {
    dispatch(toggleTooltip(TOOLTIP_LIST.freeVeinHelper));
  };

  const handleClickEdgeBandingWidthHelper = () => {
    dispatch(toggleTooltip(TOOLTIP_LIST.edgeBandingWidthHelper));
  };

  const handleClickEdgeBandingHeightHelper = () => {
    dispatch(toggleTooltip(TOOLTIP_LIST.edgeBandingHeightHelper));
  };

  useEffect(() => {
    dispatch(
      setFormIsValid(
        cutName !== null &&
          cutName !== '' &&
          width !== null &&
          width !== '' &&
          !widthWithError &&
          height !== null &&
          height !== '' &&
          !heightWithError &&
          amount > 0 &&
          freeVein !== null
      )
    );
  });

  useEffect(() => {
    if (edgeBandings.length === 0) dispatch(getEdgeBandingsAsync(tenant));
  }, []);

  const abbr = UNITS_OF_MEASUREMENT.find(
    (element) => element.key === unitOfMeasurement
  )?.abbreviation;

  return (
    <CuttingFormStyled data-testid="cutting-form">
      <UnitOfMeasurementInputStyled>
        <LabelStyled>{t('MEASURE_UNIT_LABEL')}</LabelStyled>
        <Radio
          value={unitOfMeasurement}
          inline={true}
          data-testid="unit-of-measurement-input"
          onChange={handleChangeUnitOfMeasurement}
        >
          {UNITS_OF_MEASUREMENT.map((unitOfMeasurement) => (
            <RadioOption key={unitOfMeasurement.key} value={unitOfMeasurement.key}>
              {unitOfMeasurement.label}
            </RadioOption>
          ))}
        </Radio>
      </UnitOfMeasurementInputStyled>
      <BoxLeftStyled>
        <LabelStyled>{t('CUT_NAME')}</LabelStyled>
        <Input
          value={cutName !== null ? cutName : undefined}
          placeholder={t('CUT_NAME_PLACEHOLDER')}
          type="text"
          data-testid="cut-name-input"
          onChange={handleChangeCutName}
        />
      </BoxLeftStyled>
      <BoxRightStyled>
        <LabelStyled>{t('QTY')}</LabelStyled>
        <InputSpinner
          data-testid="amount-input"
          value={amount}
          onChange={handleChangeAmount}
          min={1}
        />
      </BoxRightStyled>
      <BoxLeftStyled>
        <LabelStyled>
          {t('WIDTH')} ({abbr})
        </LabelStyled>
        <Input
          type="decimal"
          data-testid="width-input"
          error={widthWithError}
          value={width !== null ? width : undefined}
          onChange={handleChangeWidth}
          onBlur={handleBlurWidth}
          helper={`Mínimo ${getValueAccordingToFactor(
            MINIMUM_VALIDATION_RULES.width,
            factor
          )} ${abbr}, máximo ${getValueAccordingToFactor(
            MAXIMUM_VALIDATION_RULES.width,
            factor
          )} ${abbr}.`}
          placeholder={`${t('EXAMPLE_SHORT')} ${getValueAccordingToFactor(
            MINIMUM_VALIDATION_RULES.width,
            factor
          )}`}
          inputMode="numeric"
        />
      </BoxLeftStyled>
      <BoxRightStyled>
        <div onClick={handleClickEdgeBandingWidthHelper} data-testid="edgebanding-helper">
          <PopoverContainer>
            <LabelStyled>
              <span>{t('EDGEBANDING')}</span> <InfoIcon />
            </LabelStyled>
            <Popover
              align={'right'}
              visible={activeTooltip === TOOLTIP_LIST.edgeBandingWidthHelper}
              size={widthScreen > BREAKPOINTS.tablet ? 290 : 213}
            >
              <PopoverContentStyled>
                <p>{t('EDGEBANDING_HELPER')}</p>
              </PopoverContentStyled>
            </Popover>
          </PopoverContainer>
        </div>
        <EdgeBandingContainer>
          <EdgeBandingButton side="left" />
          <EdgeBandingButton side="right" />
        </EdgeBandingContainer>
      </BoxRightStyled>
      <BoxLeftStyled>
        <LabelStyled>
          {t('HEIGHT')} ({abbr})
        </LabelStyled>
        <Input
          type="decimal"
          data-testid="height-input"
          error={heightWithError}
          value={height !== null ? height : undefined}
          onChange={handleChangeHeight}
          onBlur={handleBlurHeight}
          helper={`Mínimo ${getValueAccordingToFactor(
            MINIMUM_VALIDATION_RULES.height,
            factor
          )} ${abbr}, máximo ${getValueAccordingToFactor(
            MAXIMUM_VALIDATION_RULES.height,
            factor
          )} ${abbr}.`}
          placeholder={`${t('EXAMPLE_SHORT')} ${getValueAccordingToFactor(
            MINIMUM_VALIDATION_RULES.height,
            factor
          )}`}
          inputMode="numeric"
        />
      </BoxLeftStyled>
      <BoxRightStyled>
        <div onClick={handleClickEdgeBandingHeightHelper} data-testid="edgebanding-helper">
          <PopoverContainer>
            <LabelStyled>
              <span>{t('EDGEBANDING')}</span> <InfoIcon />
            </LabelStyled>
            <Popover
              align={'right'}
              visible={activeTooltip === TOOLTIP_LIST.edgeBandingHeightHelper}
              size={widthScreen > BREAKPOINTS.tablet ? 290 : 213}
            >
              <PopoverContentStyled>
                <p>{t('EDGEBANDING_HELPER')}</p>
              </PopoverContentStyled>
            </Popover>
          </PopoverContainer>
        </div>
        <EdgeBandingContainer>
          <EdgeBandingButton side="bottom" />
          <EdgeBandingButton side="top" />
        </EdgeBandingContainer>
      </BoxRightStyled>
      <FreeVeinInputStyled>
        <LabelStyled>{t('VETA')}</LabelStyled>
        <Switch data-testid="free-vein-input" active={!!freeVein} onChange={handleChangeFreeVein} />
        <FreeVeinHelperStyled data-testid="free-vein-helper" onClick={handleClickFreeVeinHelper}>
          <PopoverContainer>
            <FreeVeinHelperContentStyled>
              <span>{t('WHAT_IS_THIS')}</span> <QuestionIcon />
            </FreeVeinHelperContentStyled>
            <Popover
              align={'right'}
              visible={activeTooltip === TOOLTIP_LIST.freeVeinHelper}
              size={widthScreen > BREAKPOINTS.tablet ? 280 : 213}
            >
              <PopoverContentStyled>
                <p>{t('VETA_HELPER')}</p>
              </PopoverContentStyled>
            </Popover>
          </PopoverContainer>
        </FreeVeinHelperStyled>
      </FreeVeinInputStyled>
    </CuttingFormStyled>
  );
};

export default CuttingForm;
